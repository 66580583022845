<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			<v-col class="EDC-Col">
				
			</v-col>
		</v-row>
		<v-dialog v-model="erpModel" width="500" persistent>
			<v-form ref="obj_form" v-model="valid" @submit.prevent="validateErp">
				<v-card>
					<v-container class="EDC-Container">
						<v-row class="EDC-Row">
							<v-col class="EDC-Col" cols="6">
					      		<v-autocomplete autocomplete :items="erpList" v-model="erpCode" label="Select Erp Name" style="margin-right:10px; margin-top:6px;" required :rules="erp_nameRules"></v-autocomplete>
					      	</v-col>
					      	<v-col class="EDC-Col" cols="6">
					      		<v-autocomplete autocomplete :items="versionListByErp" v-model="erpVersion" label="Select Erp Version" style="margin-right:10px; margin-top:6px;" required :rules="erp_versionRule"></v-autocomplete>
					      	</v-col>
					    </v-row>
					    <v-row class="EDC-Row">
	            			<v-col class="EDC-Col" cols="12" align="right">
								<vc-button itemText="Save" @click.native="validateErp()"></vc-button>
							</v-col>
						</v-row>
					</v-container>
	        	</v-card>
    	</v-form>
		</v-dialog>
	</v-container>
</template>
<script>
	import { post as postToServer } from '../methods/serverCall.js';
	import config from '../config.json';
	import {mapActions} from 'vuex'
	import {Archivist_Product,Workflow_Product,Dataviewer_Product} from './../constants/constants.js'
	export default {
		name:"designLandingPage",
		data(){
			return {
				valid:true,
				clientId:this.$session.get("client_id"),
				erpCode:'',
				erpVersion:'',
				erpDetails:[],
				erpList:[],
				versionListByErp:[],
				erpModel:false,
				erp_nameRules: [v => !!v || 'ERP Name is required.'],
				erp_versionRule: [v => !!v || 'ERP Version is required.'],
			}
		},
		watch:{
			'erpCode':{
				handler(newvalue){
					let _this = this
					_this.versionListByErp= []
					let versionList = _.filter(_this.erpDetails,(obj)=>{
						return obj.erp_code == _this.erpCode
					})
					if(versionList){
						_this.versionListByErp = _.uniq( _.map(versionList,"erp_version"))
						_this.erpVersion = _this.versionListByErp[0]
					}
				},
			}
		},
		mounted() {
			let objectDetails = this.$session.get('redirection_object_details')
			if(objectDetails.check_erp === "true" || objectDetails.copy === 'Y' || !objectDetails.object_id){
				objectDetails['erpCode'] = "CST"
				objectDetails['erpVersion'] = "001"
				this.checkErpAvailablity(objectDetails)
			}
			else{
				this.manageRedirection(objectDetails)
			}
		},
		methods:{
			...mapActions(['setDesignerFor']),
			manageRedirection(objectDetails){
				let objectParams = { 'env_id': objectDetails.last_env_id, 'env_name': objectDetails .last_env_name}
				let fetchDSDetails = false
				if(objectDetails.erpCode)
					objectParams['erpCode'] = objectDetails.erpCode
				if(objectDetails.erpVersion)
					objectParams['erpVersion'] = objectDetails.erpVersion
				if(objectDetails.is_read_only)
					objectParams['is_read_only'] = true
				if(objectDetails.create_new_version === 'true'){
					objectParams['create_new_version'] = true
				}
				if(objectDetails.allow_create === 'true')
					objectParams['allow_create'] = true
				if(objectDetails.copy === 'Y'){
					fetchDSDetails = true
					objectParams['copy'] = 'Y'
				}
				if(objectDetails.object_type_code)
					objectParams['object_type_code'] = objectDetails.graphFor
				if(objectDetails.object_revision)
					objectParams['object_revision'] = objectDetails.object_revision
				if(objectDetails.product_name)
					objectParams['product_name'] = objectDetails.product_name
				if(objectParams['product_name'] === 'PATHFINDER')
					objectParams['product_name'] = Dataviewer_Product.toLocaleLowerCase()
				this.$session.set('selected_env',{"id":objectDetails.last_env_id,"name":objectDetails.last_env_name,"allow_create":objectDetails.allow_create})
				// console.log('objectDetails',objectParams)
				if(objectDetails.graphFor === 'PD'){
					this.setDesignerFor('Process Definition Designer')
					if(objectDetails.object_id)
						objectParams['process_def_id'] = objectDetails.object_id
					
					this.$router.push({ name: 'editprocessdef', params: objectParams})
				}
				if(objectDetails.graphFor === 'BO'){
					this.setDesignerFor('Business Object Designer')
					if(objectDetails.object_id){
						objectParams['business_object_graph_id'] = objectDetails.object_id
						objectParams["datasource_id"] = objectDetails.datasource_id
						objectParams["datasource_name"] = objectDetails.ds_name
						objectParams['ds_index'] = objectDetails.ds_index
					}
					else
						fetchDSDetails = true

					if(fetchDSDetails){
						objectParams['datasource_id'] = ''
						objectParams['datasource_name'] = ''
					}
					this.$router.push({ name: "businessobject", params: objectParams})
				}
				if(objectDetails.graphFor === 'DM'){
					this.setDesignerFor('Datamap Designer')
					if(objectDetails.object_id)
						objectParams['data_map_id'] = objectDetails.object_id
					this.$router.push({ name: 'datamapdesigner', params: objectParams})
					return
				}
				if(objectDetails.graphFor === 'BV'){
					this.setDesignerFor('Business View Designer')
					if(objectDetails.object_id)
						objectParams['business_view_id'] = objectDetails.object_id
					this.$router.push({ name: 'businessviewfor', params: objectParams})
					return
				}
				if(objectDetails.graphFor === 'WF' && objectDetails.json){
					// this.setDesignerFor('Workflow')
					if(objectDetails.object_id)
						objectParams['workflow_id'] = objectDetails.object_id
					if(objectDetails.completed_node_id)
						objectParams['completed_node_id'] = objectDetails.completed_node_id
					if(objectDetails.json)
						objectParams['workflow_json'] = objectDetails.json
					if(objectDetails.running_node_id)
						objectParams['running_node_id'] = objectDetails.running_node_id
					if(objectDetails.ticket_name)
						objectParams['ticket_name'] = objectDetails.ticket_name
					if(objectDetails.ticket_id)
						objectParams['ticket_id'] = objectDetails.ticket_id
					this.$router.push({ name: 'WorkflowStatus', params: objectParams})
					return
				}
				else if(objectDetails.graphFor === 'WF'){
					this.setDesignerFor('Workflow')
					if(objectDetails.object_id)
						objectParams['workflow_id'] = objectDetails.object_id
					this.$router.push({ name: 'WorkflowDesigner', params: objectParams})
					return
				}
				else if(objectDetails.graphFor === 'TL'){
					this.setDesignerFor("Task List Designer")
					if(objectDetails.object_id)
						objectParams['object_id'] = objectDetails.object_id
					if(objectDetails.object_revision)
						objectParams['object_version'] = objectDetails.object_revision
					this.$router.push({ name: 'manageTaskList', params: objectParams})
					return
				}
			},
			checkErpAvailablity(objectDetails){
				let _this = this
				let data = {"client_id":this.client_id}
				let end_point = config.REPOSITORY_API_URL + '/fetch_all_erp_version'
				postToServer(_this, end_point, data, true).then(response => {
					if(response && response.length){
						_this.erpDetails = response
						response.forEach((obj)=>{
							if(_this.erpList.indexOf(obj.erp_code)===-1)
								_this.erpList.push(obj.erp_code)
							_this.erpCode = _this.erpList[0]
							_this.erpModel = true
						})
					}
					else{
						_this.manageRedirection(objectDetails)
					}
				}).catch(error=>{
					_this.manageRedirection(objectDetails)
				})
			},
			validateErp(){
	        	if (this.$refs.obj_form.validate()) {
	        		let objectDetails = this.$session.get('redirection_object_details')
	        		objectDetails['erpCode'] = this.erpCode
					objectDetails['erpVersion'] = this.erpVersion
					this.manageRedirection(objectDetails)
	        	}
			},
		}
	}
</script>