<template>
    <v-container class="EDC-Row">
        <v-row no-gutters>
            <v-col>
                <v-container class="EDC-Row breadcrumbBackground">
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col text-truncate" align="left" cols="11">
                            <ul class="breadcrumb" style="display:inline-flex;padding-left:0px !important;">
                                <li>{{env_name}}</li>
                                <li>
                                    Manage Task List
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-card class="pa-2">
                    <v-row class="EDC-Row pt-3" >
                        <v-col cols="3" class="EDC-Col EDC-ColsSpacing" align="left">
                            <v-text-field label="Name of Task List" v-model="taskListName" hide-details dense :disabled="isReadOnly"></v-text-field>
                        </v-col>
                        <v-col cols="3" class="EDC-Col EDC-ColsSpacing business-view-text-area">
                            <v-textarea label="Description" rows=1 v-model="taskListDescription" hide-details dense :disabled="isReadOnly"></v-textarea>
                        </v-col>
                        <v-col cols="1" class="EDC-Col EDC-ColsSpacing" style="padding-top:15px !important;">
                            Version: {{obejctVersion}}
                        </v-col>
                        <v-col class="EDC-Col" cols="2">
                            <!-- <sapn class="EDC-DesignerHeader"><b>BUSINESS VIEW DESIGNER</b></sapn> -->
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-inline">
                            <svgicon class="svg-icon-grid svg-fill-grid ma-1" 
                            name="add_multiple_v2"  title="Create New Section"
                            @click="onNewSection"></svgicon>
                            <v-tabs v-model="activeTab" non-linear class="pl-0" @change="onTabChange">
                                <draggable v-model="taskSectionList" @end="updateSequence" class="tab-list" :tag="'div'">
                                    <v-tab
                                    v-for="(item, index) in taskSectionList"
                                    :key="'tab'+index"
                                    class="draggable-tab"
                                    :draggable="true"
                                    @click="setActiveTab(index)" 
                                    :active="activeTab === index"
                                    >
                                    {{ item.sectionName }} 
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                                                    <v-icon 
                                                        v-bind="{ ...attrs, ...menuAttrs }"
                                                        v-on="{ ...on, ...menuOn }">
                                                        mdi-dots-horizontal
                                                    </v-icon>
                                                </template>
                                                <v-card class="pa-0">
                                                    <v-list dense>
                                                        <v-list-item @click="editSection(item)">
                                                            <v-list-item-icon class="pa-0 ma-0">
                                                                <v-icon small>mdi-pencil</v-icon> <!-- Edit icon -->
                                                            </v-list-item-icon>
                                                            <v-list-item-title>Edit Section</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="removeTab(index)">
                                                            <v-list-item-icon class="pa-0 ma-0">
                                                                <v-icon small>mdi-delete</v-icon> <!-- Delete icon -->
                                                            </v-list-item-icon>
                                                            <v-list-item-title>Delete Section</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-menu>
                                        </template>
                                        <span>Actions for section</span>
                                    </v-tooltip>
                                    </v-tab>
                                </draggable>

                                <!-- <v-tab v-for="(item,index) in taskSectionList" class="EDC-Tabs" :key="'tab'+index">
                                    <span>{{ item.sectionName }}</span>
                                </v-tab> -->
                            </v-tabs>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                            <v-tabs-items v-model="activeTab" class="px-2">
                                <v-tab-item v-for="(item,index) in taskSectionList"  :key="'tab'+index"
                                class="pb-2" eager>
                                    <edc-data-grid  :dataList="item.tableList"
                                    @onNew="onNewTask(item)" @onEdit="onEditTask(item, $event)" @dblclick="onEditTask(item, $event)"
                                     @onDelete="onDeleteTask(item, $event)" ></edc-data-grid>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row">
                        <v-col class="EDC-Col d-flex justify-end pr-4">
                            <vc-button @click.native="saveTaskList" item-text="Save"/>
                            <vc-button item-text="Cancel" @click.native="showClosePopUp"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-dialog v-model="showPopup" persistent :width="popupWidth">
                    <section-task-popup :popupObject="popupObject" @closePopup="showPopup=false"
                    @saveTask="saveTask" @saveSection="saveSection"/>
                </v-dialog>
            </v-col>
        </v-row>
        
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        <simplert :useRadius="true" :useIcon="true" ref="simplert">
                </simplert>
    </v-container>
</template>
<style scoped>
.tab-list {
  display: flex;
  padding: 0;
  list-style: none;
}
.draggable-tab {
  margin-right: 10px;
  cursor: move;
  text-transform: none;
  font-size: 12px;
  padding: 2px 4px;
}
</style>
<script>
    import config from "../../config.json";
    import { post as postToServer } from '../../methods/serverCall.js';
    import Simplert from 'vue2-simplert'
    import {CLIENT_SIDE} from '@/data/macros.js'
    import sectionAndTaskPopUp from "./sectionAndTaskPopUp.vue";
    import draggable from "vuedraggable";
    import {Snackbar_Default_Timeout} from '@/constants/constants.js'
    export default{
        name:"ManageTaskList",
        components:{
            'section-task-popup':sectionAndTaskPopUp,
            draggable,
            Simplert
        },
        data(){
			return {
				clientId:this.$session.get("client_id"),
				userName:this.$session.get("email"),
				productName:this.$route.params.product_name,
                env_id: this.$session.get("selected_env").id,
				env_name: this.$session.get("selected_env").name,
                taskListName:"Untitled Task List",
                taskListDescription:"",
                isReadOnly:true,
                objectId: null,
                obejctVersion:1,
                snackbar:false,
                snackbartext:'',
                colorValue:'error',
                snackbartimeout: Snackbar_Default_Timeout,
                activeTab:0,
                tableList: {
                    itemkey:'taskId',
                    headers: [
                        // {
                        //     text: 'Task Id',
                        //     value: 'id',
                        //     title: 'Task Id',
                        // },
                        {
                            text: 'Task Name',
                            value: 'taskName',
                            title: 'Name of Task'
                        },
                        {
                            text: 'Task Description',
                            value: 'taskDescription',
                            title: 'Description Of The Task'
                        }
                    ],

                    actions: [
                        
                    ], //if we need conditional action in row then provide key
                    sorting_type: CLIENT_SIDE,
                    filterType: CLIENT_SIDE,
                    paginationType: CLIENT_SIDE,
                    total_count: 0,
                    rows: [],
                    selected_rows: [],
                    syncHeaderScroll: false,
                    hideExport:true,
                    hideShowColumns:true,
                    hideFilter:true
                },
                taskSectionList: [],
                currentSection:{},
                showPopup:false,
                popupObject:{},
                popupWidth:400,
                isUpdate: false
            }
        },
        mounted(){
            var _this = this
            this.tableList.actions=[
                {
                    'text':'new',
                    'key':"taskId", 
                    selectType:"single", 
                    role:true,index:1, 
                    "title":"Add New Task"
                },
                {
                    'text': 'edit',
                    'key': "taskId",
                    selectType:"single",
                    role:true,
                    index:2
                },
                {
                    'text': 'delete',
                    'key': "taskId",
                    selectType:"single",
                    role:true,
                    index:3
                }
                ]
            this.objectId = this.$route.params.object_id
            this.obejctVersion = this.$route.params.object_version
            if(_this.$route.params.isReadOnly==='N')
			    _this.isReadOnly = false
            _this.isReadOnly = false
            _.forEach(_this.taskSectionList,(obj)=>{
                obj['tableList'] = _.cloneDeep(_this.tableList)
            })
            if(this.objectId){
                this.isUpdate = true
                this.getTaskList()
            }
            
        },
        methods:{
            getTaskList(){
                var _this = this
                var data = {
                    "envId": this.env_id,
                    "clientId": this.clientId,
                    "objectId": this.objectId,
                    "objectVersion": this.obejctVersion
                }
                var url = config.PUBLISHER_URL + "/get_task_list_by_id"
                this.loader = true
                postToServer(_this, url, data).then(response=>{
                    _this.taskListName = response.object_name
                    _this.taskListDescription = response.object_desc
                    _this.objectId = response.object_id
                    _this.objectVersion = response.object_version,
                    _this.object_json = response.object_json
                    response.object_json.sections.forEach((obj,indx)=>{
                        let section = _.cloneDeep(obj)
                        let tableList= _.cloneDeep(_this.tableList)
                        tableList['rows'] = obj.tasks
                        delete section.tasks
                        section['isInitiated'] = true
                        section['tableList'] = tableList
                        _this.taskSectionList.push(section)
                        _this.$nextTick(()=>{
                            _this.taskSectionList[indx]['tableList']['rows'] = _.cloneDeep(obj.tasks)
                        })
                        
                    })
                    if(_this.$route.params.copy === 'Y'){
                        _this.taskListName = 'Copy of '+response['object_name']
                        _this.object_name = 'Copy of '+response['object_name']
                        _this.objectId = null
                    }
                    console.log(_this.taskSectionList)
                    // _.forEach(_this.taskSectionList,(obj)=>{
                    //     obj['tableList'] = _.cloneDeep(obj.tableList.rows)
                    // })
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            
            //Section methods
            onTabChange(){
                // in vu2 directing binding not happening
                var _this = this
                // if(!this.taskSectionList[this.activeTab].isInitiated){
                //     this.taskSectionList[this.activeTab].isInitiated = true
                //     this.$nextTick(()=>{
                //         _this.taskSectionList[_this.activeTab]['tableList'] = _.cloneDeep(_this.tableList)
                //     })
                // } 
                // else{
                //     let rows =  _this.taskSectionList[_this.activeTab]['tableList']['rows']
                //     this.$nextTick(()=>{
                //         _this.taskSectionList[_this.activeTab]['tableList']=_.cloneDeep(_this.tableList)
                //         _this.taskSectionList[_this.activeTab]['tableList']['rows'] = rows
                //     })
                // }
            },
            onNewSection(){
                this.popupWidth = 400
                this.popupObject = {
                    "for":"section", 
                    "taskListName": this.taskListName, 
                    "taskListDescription": this.taskListDescription, 
                    "objectId": this.objectId}
                this.showPopup = true
            },
            saveSection(sectionData, sectionId){
                this.showPopup = false
                const sectionObjIndex = _.findIndex(this.taskSectionList, { sectionId: sectionId });
                if (sectionObjIndex !== -1) {
                    // change section name ,description here
                    this.taskSectionList[sectionObjIndex] = { 
                        ...this.taskSectionList[sectionObjIndex],
                        ...{
                            "sectionName": sectionData.sectionName,
                            "sectionDescription": sectionData.sectionDescription
                        }
                    };
                    this.updateSequences();  
                    return
                }

                sectionData['tableList'] = _.cloneDeep(this.tableList)
                sectionData.isInitiated = false
                this.taskSectionList.push(sectionData)
                this.activeTab = this.taskSectionList.length -1
                this.onTabChange() 
                this.updateSequences();               
            },
            
            updateSequence() {
            this.updateSequences();
            },
            updateSequences() {
            this.taskSectionList.forEach((tab, index) => {
                tab.sectionSequence = index + 1;
            });
            },
            removeTab(index) {
                this.taskSectionList.splice(index, 1);
                if (this.activeTab === index) {
                    this.activeTab = null;
                }
            },
            setActiveTab(index) {
                this.activeTab = index;
            },
            editSection(item) {
                this.showPopup = true
                this.popupWidth = 400
                this.popupObject = _.cloneDeep(item)
                this.popupObject.for = "section"
                this.popupObject.sectionName = item.sectionName
            },

            
            //Task methods
            onNewTask(sectionObj){
                this.popupWidth = 400
                this.currentSection = sectionObj
                this.popupObject = {
                    "for":"task",
                    "sectionId":this.currentSection.sectionId,
                    "sectionName":this.currentSection.sectionName,
                    "onNewTask": true
                }
                this.showPopup = true
            },
            onEditTask(sectionData, taskData){
                this.popupWidth = 400
                this.currentSection = sectionData
                this.popupObject = _.cloneDeep(taskData)
                this.popupObject.sectionId = this.currentSection.sectionId,
                this.popupObject.sectionName = this.currentSection.sectionName
                this.showPopup = true
            },
            onDeleteTask(sectionData, taskData) {
                const sectionObjIndex = _.findIndex(this.taskSectionList, { sectionId: sectionData.sectionId });
                if (sectionObjIndex !== -1) {
                    const section = this.taskSectionList[sectionObjIndex];
                    const taskObjIndex = _.findIndex(section.tableList.rows, { taskId: taskData.taskId });
                    if (taskObjIndex !== -1) {
                        section.tableList.rows.splice(taskObjIndex, 1);
                        this.$set(this.taskSectionList, sectionObjIndex, {
                            ...section,
                            tableList: {
                                ...section.tableList,
                                rows: [...section.tableList.rows]
                            }
                        });
                        this.updateSequences(); 
                    }
                }
            },

            onCellEvent(record){
                this.onEdit(record);
            },
            
            saveTask(task_data, associatedSectionId){
                const sectionObjIndex = _.findIndex(this.taskSectionList, { sectionId: associatedSectionId });
                if (sectionObjIndex !== -1) {
                    const taskObjIndex =  _.findIndex(this.taskSectionList[sectionObjIndex].tableList.rows, { taskId: task_data.taskId });
                    if(taskObjIndex !== -1){
                        this.$set(this.taskSectionList[sectionObjIndex].tableList.rows, taskObjIndex, {
                            ...this.taskSectionList[sectionObjIndex].tableList.rows[taskObjIndex],
                            ...task_data
                        });
                        // this.$nextTick(()=>{
                            // this.taskSectionList[sectionObjIndex].tableList.rows[taskObjIndex]=task_data
                        // })
                    this.showPopup=false
                    this.taskSectionList[sectionObjIndex].tableList.selected_rows = [task_data]
                    this.updateSequences();
                    return
                    }
                }
                
                // modify here your data
                this.currentSection.tableList.rows.push(task_data)
                this.showPopup=false
            },
            
            saveTaskList(){
                var _this = this
                var data = {
                    "client_id": this.clientId,
                    "product_name": "PATHFINDER",
                    "env_id": this.env_id,
                    "env_name": this.env_name,
                    "object_id": this.objectId,
                    "object_type_code": "TL",
                    "object_name": this.taskListName,
                    "object_desc": this.taskListDescription,
                    "object_revision": "1",
                    "added_by": this.userName,
                    "object_json": {"sections": []}
                }
                for(let i=0;i<this.taskSectionList.length;i++){
                    let currentSection = this.taskSectionList[i]
                    let section = {
                        "sectionId":currentSection.sectionId,
                        "sectionSequence":currentSection.sectionSequence,
                        "sectionName":currentSection.sectionName,
                        "sectionDescription":currentSection.sectionDescription,
                        "tasks":currentSection.tableList.rows
                    }
                    data.object_json.sections.push(section)
                }
                
                console.log(JSON.stringify(data))
                var url = config.REPOSITORY_API_URL + "/manage_object"
                this.loader = true
                postToServer(_this, url, data).then(response=>{
                    _this.objectId = response.object_id
                    this.loader = false
                    this.snackbar = true
                    this.colorValue = 'success'
                    this.snackbartext = 'Task List added successfully';
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            
		    showClosePopUp(){
                alert("close")
				
			if(this.isUpdate){
				let confirmFn = function () {
		        	this.$parent.saveTaskList()
		        }
		        let closeFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Some changes are not saved, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Save & Close',
		          customCloseBtnText:'Close Anyway',
		          onConfirm: confirmFn,
		          onClose:closeFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
			else{
				let confirmFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Tab will get close, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Close',
		          customCloseBtnText:'Cancel',
		          onConfirm: confirmFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
			
		},
		closeTab(){
			window.top.close()
		},
        }
    }
</script>