<template>
	<div>
		<v-flex xs12 style="margin-left:15px;margin-right:15px;margin-top:5px;">
			<v-card v-if="dataList.headers.length > 0" pl-1 pr-1>
				<v-container class="EDC-Container EDC-GridHeaderContainer" v-if="!dataList.hideActions || !dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">
					<v-row no-gutters align-content="center">
						<v-col cols="2" v-if="!dataList.hideActions" align="start">
							<v-row class="EDC-Row">
								<v-col class="EDC-Col">
									<svgicon v-if="showAddNew && !selected.length" title="Add New" class="EDC-GridIcon svg-icon-grid svg-fill-grid" name="add_v2" :original="true" @click="createEvent('record', 'onNew')"></svgicon>								
									
									<action-panel :key="ActionPanelremountTime" :selectedArray="selected" @createEvent="createEvent"
									:data="gridActionObject" @onCancelEditing="onCancelEditing"></action-panel>
								</v-col>
							</v-row>
						</v-col>
						<v-col align="start">
							<v-chip v-if="!dataList.hideFilter || isGridForDataviewer" v-for="(item,index) in selectChips" :key="item" color="primary" style="margin-top: 3px !important; margin-right: 2px !important; margin-left: 2px !important;float:left;" @click:close="chipDeleted(item,index)" small close>{{item}}</v-chip>
						</v-col>
						<v-col cols="1" align="end" v-if="!dataList.hideFilter || !dataList.hideExport || !dataList.hideShowColumns">

						<!-- <template v-slot:activator="{ on }"> -->
							<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" name="search_v2" slot="activator" :original="true" @click="displayCriteriaPopUp()" v-if="!dataList.hideFilter" title="Apply Filter"></svgicon>
						<!-- </template> -->

							<action-panel @onDownload="onDownload" :showDownload="!dataList.hideExport" v-if="!dataList.hideExport" :selectedArray="selected">

							</action-panel>
							


							<v-menu offset-y class="EDC-GridColumnHide" close-on-click :close-on-content-click='false'>
								<template v-slot:activator="{ on }">
									<!-- <v-icon  v-on="on" class="EDC-GridIcon" title="Show / Hide Columns" id="smallicon" v-if="!dataList.hideShowColumns">settings_ethernet</v-icon> -->
									<svgicon class="svg-icon-grid svg-fill-grid EDC-GridIcon" v-on="on" name="columns_show_hide_v2" slot="activator" :original="true"  v-if="!dataList.hideShowColumns"title="Hide/Show Columns"></svgicon>

								</template>
								<v-card class="EDC-VCardColumn">
									<v-combobox :items="allHeaders" multiple :menu-props="{ maxHeight: '300' }" hide-details v-model="visibleCols" item-text="text" item-value="text" @change="hideDataGridColumns" class="EDC-ControlPadding">
										<template v-slot:selection="{ item, index }">
											<span v-if="index === 0">{{ item.text }}</span>
											<span v-if="index === 1">(+{{ visibleCols.length - 1 }} Cols)</span>
										</template>
									</v-combobox>
								</v-card>
							</v-menu>
						</v-col>
					</v-row>
				</v-container>
			<v-data-table dense v-model="selected" :multi-sort="!dataList.singleSort"
			:headers="dataList.headers"
			:items="rowsToDisplay"
			:item-key="dataList.itemkey"
			:page="page"
			:items-per-page="perPage"
			:server-items-length="dataList.total_count"
			:show-select="!dataList.hideSelect"
			class="elevation-1" 
			hide-default-footer
			:loading="dataList.tableLoading"
			@page-count="pageCount = $event" @update:options="updateTableOptions" id="edcGrid">

			<template v-for="(h,i) in dataList.headers" v-slot:[`header.${h.value}`]="{ header }">
				<v-tooltip top :key="'h'+i">
					<template v-slot:activator="{ on }">
						<span v-on="on" @mouseover="showTooltip = false" v-if="h.is_attachment_col">
							<svgicon class="svg-icon-inline svg-fill-grid" name="attachment" slot="activator" :original="true" title="Attachment Available"></svgicon>
						</span>
						
						<span v-on="on" @mouseover="showTooltip = false" v-else>{{h.displayHeader || h.text}} <span v-if="h.func"> ({{h.func.charAt(0).toUpperCase() + h.func.slice(1)}})</span></span>
					</template>
					<span>{{getColTooltips(h.text,h)}}</span>
				</v-tooltip>
			</template>
			<template v-slot:body="{ items }">
				<tbody style="cursor:pointer" @mouseleave="showTooltip = false">
					<tr  v-if="showInlineFilter " @mouseover="showTooltip = false">
						<td  v-if="!dataList.hideSelect" class="text-align:left;">
							<v-col class="EDC-Col"  cols="" align-self="left">
							<svgicon class="svg-icon-inline svg-fill-grid" v-if="inlineFilterList.length > 0" style="display:grid;margin: 12%" :original="true" name="delete" @click="onCleareAllInlineFilter()"></svgicon>
							</v-col>
						</td>
						
						<td v-for="(h,i) in dataList.headers" :key="i+'fitlerheader'" style="text-align:left;" :class="{'hideCol':h.align===hideColProp}">
							<v-row class="EDC-Row" v-if="h.useRadio || h.showRadio && h.showDeleteInlineFilterIcon && h.hideInlineFilter || h.hideFilter">
								<v-col class="EDC-Col" align-self="left">
									<svgicon class="svg-icon-inline svg-fill-grid" v-if="inlineFilterList.length > 0" style="display:grid;margin: 15%" :original="true" name="delete" @click="onCleareAllInlineFilter()"></svgicon>
								</v-col>
							</v-row>
							<v-row class="EDC-Row" v-else>
								<v-col class="EDC-Col" >
									<v-text-field autocomplete="off" v-if="!h.hideInlineFilter && !h.is_temp_col" clearable rounded :id="i+'inlineFilter'"
									type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" hide-details dense @click:clear="clearInlineFilter($event,h,h.filtertext)" @keyup.prevent="keyUpInlineFilter($event,h, h.filtertext)" @mouseover="showTooltip = false"></v-text-field>

							<!-- <input  v-if="!h.hideInlineFilter" style="border-radius:5px !important;border:1px solid; padding-left: 5px;" type="text" v-model="h.filtertext" @keypress.enter.prevent="applyInlineFilter($event,h, h.filtertext)" v-focus width="25px"/> -->
							<!-- <v-span v-else></v-span> -->
								</v-col>
								<v-col class="EDC-Col" cols="2" v-if="h.advanceFilter && !dataList.hideAdvanceFilter" align-self="center">
									<svgicon class="svg-icon-inline svg-fill-grid" name="search_v2" slot="activator" :original="true" :id="i+'inlineFilterIcon'" @click="onClickAdvanceFilter(h)" style="transform: rotate(90deg);" :title="h.advanceFilterFor"></svgicon>
								</v-col>
							</v-row>
						</td>
					</tr>
					<tr v-if="dataList.rows.length === 0">
						<td :colspan="dataList.headers.length" class="EDC-NoRecordText">{{NoDataFound}}</td>
					</tr>
					<tr v-if="dataList.rows.length > 0" v-for="(item,index) in items" :key="'item'+index" @dblclick="rowDoubleClick(item,index)" @contextmenu.prevent="rowClick($event,item,index)">
						<td v-if="!dataList.hideSelect" >
							<v-checkbox v-model="selected" :value="item" style="margin:0px;padding:0px" hide-details color="primary-lighten2" @change="checkboxSelectionChange"></v-checkbox>
						</td>

							<td v-for="(header,headerindex) in dataList.headers" :style="{'text-align':header.align|| 'left'}"   :key="'header'+headerindex" :title="item['formatedTitle'] || item[header.value]" :class="{'hideCol':header.align===hideColProp}" @mouseover="mouseEnterOnTd($event,header,item[header.value],item)" @mouseleave="mouseLeaveTd" @click="copySelectedRowsToClipboard($event)">

								<svgicon v-if="header.is_attachment_col && item['has_attachment']" class="svg-icon-inline svg-fill-grid" name="attachment" slot="activator" :original="true" :id="index+'attachment'" @click="loadMediaAttachmentDetails(index,item.attachment_details)" title="Attachment Available"></svgicon>

								<!-- v-progress-circular in td -->
								<v-progress-circular v-else-if="header.useProgress"
								:rotate="-90"
								:width="2"
								:size="24"
								color="primary"
								:value="item[header.value]"
								><span style="font-size:10px !important;">{{item[header.value]}}</span>
							</v-progress-circular>

							<!-- v-chip in td -->
							<v-chip style="margin-top:1px;margin-bottom:1px;" v-else-if="header.useChip" :color="getColor(header.chipFor,item[header.value])" small dark>{{getFormatedData(header,item[header.value])}}</v-chip>

							<!-- v-checkbox in td -->
							<v-checkbox v-else-if="header.useCheckbox && item.is_row_editable" v-model="item[header.value]" :value="item[header.value]" style="margin:0px;padding:0px" hide-details color="primary-lighten2" :indeterminate="item.useIndeterminateState" @change="checkChanged($event,header,item)" :disabled="setDisabled(header,item)"></v-checkbox>

							<!-- v-autocomplete in td . It should not be 'clearable'.If you keep clearable it will cause the problem in add policy-->
							<v-autocomplete v-else-if="header.useAutoComplete && item.is_row_editable" hide-details="auto" v-model="item[header.value]"  :items="header.option" dense :item-text="header.item_text" 
							:label="header.label || 'select'" :item-value="header.item_value" @input="changeAutoCompleteSelection($event,item,header,headerindex,index)" :return-object="header.returnObject"></v-autocomplete>

							<!--Edc calender in td. Keep this above the textbox. Because on policy date if user select actual date policy value type then this should get displayed-->
							<edc-calender :input="item[header.value]" @update="setDate(item, header.value, ...arguments)"
							v-else-if="item.is_row_editable && ((item.value_type === 'Actual Date' && header.useTextField) || header.useCalender)" :desne="true"> </edc-calender> 


							<!-- v-textbox in td -->
							<v-text-field v-else-if="header.useTextField && item.is_row_editable" hide-details="auto" v-model="item[header.value]" dense class="EDC-TextField" @keypress="validateInput($event,header.inputType)" :disabled="header.disabled" @focus="AddRowForGridEditing(item)" @blur="AddRowForGridEditing(item)" :label="header.label"></v-text-field>

							<v-radio-group v-model="single_selected_row"  hide-details dense v-else-if="header.useRadio" @change="radioSelectionChange(item)">
                            	<v-radio class="radioClass" :color="colorCode" :value="item" hide-details dense>
                            		
                            	</v-radio>
                        	</v-radio-group>
							<!-- normal td -->
							<span v-else-if='header.LVL' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getIndentedLVL(item[header.value])}}</span>

							<span v-else-if='header.avoidTrim' @click="copyToClipboard($event,header)" class='keep-spaces'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>

							<span v-else @click="copyToClipboard($event,header)" class='singleLine'>{{getFormatedData(header,item[header.value],item,header.is_key_json)}}</span>
						</td>
					</tr>
					<tr  v-if="showSummaryFooter">
						<td v-if="!dataList.hideSelect" class="text-align:left;">
						
						</td>
						<td v-for="(h,i) in dataList.headers" :key="i+'inlinefooterheader'" style="text-align:left;" :class="{'hideCol':h.align===hideColProp}">
							<v-row class="EDC-Row">
								<v-col class="EDC-Col">
									<v-text-field autocomplete="off" v-if="getSummaryFooterValue(h)" :id="i+'inlinefooter'"
									type="text" :value="getSummaryFooterValue(h)" class="right_aligned_text summary-text-field" readonly hide-details dense :title="h.summary_func"></v-text-field>
								</v-col>
								
							</v-row>
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>

		<v-container style="max-height:50px;padding:0px;" v-if="showTableFooter">
			<v-row no-gutters dense xs12>
				<v-col cols="4" class="EDC-DashboardCol">
					<v-row no-gutters v-if="!dataList.hideItemPerPage">
						<v-col cols="8" style="margin-left:5%;">
							<v-select :items="perPageArray" hide-details v-model="perPage" style="width:50px;padding:0px;" @input="onPerPageChange"></v-select>
							<span style="position: absolute;left:60px;margin-top:-22px;" class="EDCTableFooter">Rows</span>
						</v-col>
						<v-col cols="3">
							<!-- <span style="position: absolute;left: 0%;margin-top:5%;" class="EDCTableFooter">Rows</span> -->
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" align-self="center">
					<span class="EDCTableFooter" v-if="!dataList.hideRowInfo && rowsToDisplay.length > 0">
					{{ (page - 1) * perPage + 1 }} - {{ (page - 1) * perPage + rowsToDisplay.length }} of {{ dataList.total_count }}</span>
					<span class="EDCTableFooter" v-else-if="!dataList.hideRowInfo">
						0 - 0 of 0
					</span>
				</v-col>
				<v-col cols="4" class="EDC-DashboardCol" justify="end" align="end" align-self="end" v-if="!dataList.hidePagination">

					<v-pagination style="justify-content:flex-end !important;float:right;" v-model="page" :length="pageCount" :total-visible="pageNumberTotalVisible" @input="onPageChange"></v-pagination>

				</v-col>
			</v-row>
		</v-container>
	</v-card>
</v-flex>
<v-layout>

	<v-dialog v-model="showCriteria"  eager style="overflow-y:none" persistent>
		<v-col>
			<v-card class="rounded-card" style="height: 325px;">
				<v-toolbar dark dense>
					<v-col class="text-md-center">Filters
						<v-icon class="text-lg-left" @click="showCriteria = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
					</v-col>
				</v-toolbar>
				<v-col pa-2 xs12 style="max-height: 225px; overflow-y: scroll;" id="step_detail_table">
					<new-edc-criteria :columns_for_filter="updatedFilter"  :column_name_list="columnList" @savedata="manageCriteria" :new_columns_for_filter="main_filterUI"></new-edc-criteria>
				</v-col>
				<vc-button outline itemText="Apply"  @click.native="saveCriteria">
				</vc-button>
			</v-card>
		</v-col>
	</v-dialog>
</v-layout>

<v-menu
      v-model="showMenu"
      :position-x="menuPositionX"
      :position-y="menuPositionY"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in contextMenuList"
          :key="item.node_id"
          @click="contextMenuClick(item)" dense
        >
          <v-list-item-title dense>{{item.display_name}}</v-list-item-title>
        </v-list-item>
        <v-divider style="margin-top:0px !important;margin-bottom:0px !important;" v-if="contextCombineMenuList.length"></v-divider>
        <v-list-item
          v-for="(item, index) in contextCombineMenuList"
          :key="item.node_id"
          @click="contextMenuClick(item)" dense
        >
          <v-list-item-title dense>{{item.display_name}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

<v-tooltip top :position-x="tooltipPositionX" :position-y="tooltipPositionY" v-model="showTooltip" class="edcTooltip">
  {{tooltipText}}
</v-tooltip>

</div>
</template>
<script>
import edccriteria from './../newcriteria.vue'
import{CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js';

import {FLITER_JOIN_OPERATOR_ARRAY_JSON,INLINE_FLITER_JOIN_OPERATOR_ARRAY_JSON,GRID_STATUS_COLOR_CODE,No_Data_Found,OEPRATOR_TO_EDC_OPERATOR} from '../../constants/constants.js'
import {
	COLOR_CODE,BTN_COLOR
} from "@/data/macros.js";
import vcButton from './../button.vue'
import moment from 'moment'
import ActionPanel from "./ActionPanel.vue"
import {manageScreenDisplay} from '@/methods/special.js'
import {isValidNumber} from '@/methods/edcdatavalidation.js'
import {POLICY_VALUE_TYPE,POLICY_VALUE_TYPE_WITH_RETENTION} from '@/data/metaData.js'

import {getJDEDate, convertToUserNativeDate} from '@/methods/DateFormat.js'
import * as JDEFormatterObj from "../../methods/JDEFormatter.js"
import * as commonFuncObj from "../../methods/commonFunc.js"

export default {
	name:'edcdatagrid',
	components:{
		'new-edc-criteria':edccriteria,
		'vc-button':vcButton,
		'action-panel':ActionPanel,
	},
	props:{
		dataList:Object,
		allTableDescriptions:Array,
		tooltipObj:Object,
		hideFooter:Boolean,
		gridFor:String,
	},
	data(){
		return {
			main_filterUI:[{
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      }],
		showInGridFilter:false,
		ActionPanelremountTime:1,
		hideColProp:' d-none',
		rowsToDisplay:[],
  		clickOnSort:false, // this variable is used to avoid sort call on first grid load.In case of server side grid updateOptions method get called. That method make getData col 2-3 time unnecessary.To avoid this overhead added this clickOnSort variable.
  		outlineColor:BTN_COLOR,
  		pageNumberTotalVisible:6,
  		page: 1,
  		pageCount: 0,
  		perPage: 10,
  		perPageArray:[5,10,15,20,25,50,100],
  		search:'',
  		selected:[],
  		defaultCaption:'Caption Not Available',
  		showCriteria:false,
  		columnList:[],
  		updatedFilter:[],
  		inlineFilterList:[], // filterlist contains inline filters
		filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
		operator_to_edc_operator:OEPRATOR_TO_EDC_OPERATOR,
  		NoDataFound: No_Data_Found,
  		selectChips:[],
  		oldSelectedChips:[],
  		allHeaders:[],
  		visibleCols:[],
  		visibleChilds:[],
  		downloadOption:false,
  		downloadParams:{
  			recordType:"all",
  			minRange:"",
  			maxRange:"",
  			fileType:"xlsx",
  			topN:0,
  		},
  		gridActionObject:{},
  		newWidOption:{},
  		showAddNew:false,
  		showAddNewWithOption:false,
  		newMenu:[
  		{title:'Create Business Object', event:'createBusinessDoc'},
  		{title:'Create Process Definition', event:'createProcessDoc'}
  		],
  		single_selected_row:{},
  		colorCode:COLOR_CODE,
  		headerToggle:true,
  		previousSort:[], // this variable is used to check user actually clicked on the sort or he just updated other table options. For more details check updateTableOptions()
  		contextMenuList:[],
  		contextCombineMenuList:[], 
  		showTooltip:false,
  		tooltipPositionX:0,
  		tooltipPositionY:0,
  		showTooltip:false,
  		tooltipText:'',
  		tooltipTimeout:'',
  		tooltipDelay:4000,
  		showMenu:false,
  		menuPositionX:0,
  		menuPositionY:0,
  		compressVeritcalSpace:false,
  		// createNewView:{"object_id":"GV00","object_name":"Create New View"},
  		showWithoutView:{"object_id":"GV01","object_name":"Default View"},
  		availableViewList:[],
  		selectedView:''
  	}
  },
  computed:{
  	showInlineFilter(){
  		if(this.compressVeritcalSpace)
  			return false
  		return !this.dataList.hideInlineFilter && (this.dataList.total_count > 5 || this.inlineFilterList.length>0)
  	},
  	showTableFooter(){
  		if(this.compressVeritcalSpace || this.hideFooter)
  			return false
  		return this.dataList.total_count>5
  	},
  	showSummaryFooter(){
  		return this.dataList && this.dataList.loadSummary && this.dataList.rows.length
  	},
  	showViewOptions(){
  		return this.selectedView && this.selectedView !='GV00' && this.selectedView != 'GV01'
  	},
  	isGridForDataviewer(){
  		return this.gridFor === 'pathfinder'
  	},
  },
  watch:{
  	'dataList':{
  		handler(newValue){
  			// clearInterval(newValue.hideRowTimer)
  			this.actualDataList = []
  			this.showTooltip = false
  			
  			this.manageActions()
  		},
  		deep:true
  	},
  	'dataList.selected_rows':{
  		handler(newValue){
			
  			// if(!newValue.length || !this.dataList.persist_selected_rows)
  			// 	return
  			// need right logic for job plan job moving
  			// newValue.forEach(function(obj){
  			// 	this.selected.push(_.cloneDeep(obj))
  			// })
  			// this.$nextTick(function () {
				this.selected = newValue
			// })
  			this.dataList.persist_selected_rows = false
  		},
  	},
  	'dataList.rows':{
  		handler(newValue){
  			let _this = this
  			if(this.dataList.paginationType!=SERVER_SIDE)
  			{
  				//code added for manish sir change.mail date: 11/march/2020
  				if(this.dataList.is_grid_inline_edit && this.inlineFilterList.length > 0 && this.selected.length > 0)
  					return
  				this.dataList.total_count = newValue.length
  				this.performClientSidePagination()
  			}
  			else{
  				// if(newValue.length > this.perPage)
  				// 	this.rowsToDisplay = newValue.slice(0,this.perPage)
  				// else
  					this.rowsToDisplay = newValue
  			}
  			this.manageSelected(this.selected)

  		},
  		deep:true
  	},
  	'dataList.updateInlineFilter':{
  		handler(newValue){
  			if(newValue)
  				this.addInlineFilterText(newValue)
  		}
  	},
  	'dataList.headers':{
  		handler(newValue){
  			this.page = 1
  			
  			if(!this.isGridForDataviewer){
  				// alert('header change')
  				this.inlineFilterList = []
  			}
  			// this.perPage = 10
  		}
  	},
  	'dataList.summaryFooter':{
  		handler(newValue){
  		},
  	},
  	'dataList.loadSummary':{
  		handler(newValue){
  			this.showSummaryToggleChanged(newValue)
  		},
  	},
  	'dataList.availableViewList':{
  		handler(newValue){
  			if(newValue){
  				this.availableViewList = _.cloneDeep(newValue)
  				// this.availableViewList.splice(0, 0, this.createNewView)
  				this.availableViewList.splice(0,0,this.showWithoutView)
  				this.availableViewList.splice(1,0,{"object_id":"====================","divider":true,"no-filter":true,   'object_id':"===================="})
  			}
  		}
  	},
  	'dataList.inputJson':{
  		handler(newValue){
  			if(!newValue || _.isEmpty(newValue))
  				return
  			if(newValue.filter_data && newValue.filter_data.length && this.isGridForDataviewer){
  				// this.updatedFilter = _.cloneDeep(newValue.filter_data)
  				this.autoFillupInlineFilter(_.cloneDeep(newValue.filter_data))
  			}
  		}
  	},
  	'dataList.selectedView':{
  		handler(newValue){
  			if(newValue){
  				this.selectedView = newValue
  			}
  			else{
  				this.selectedView = ''
  			}
  		}
  	},
  	'dataList.showDescriptionAsHeader':{
  		handler(newValue){
  			this.manageHeaderText(newValue)
  		}
  	},
  	'rowsToDisplay':{
  		handler(newValue){
			// if(this.dataList.DefaultSelectAll)
			// 	this.selected = newValue
			// else
			// 	this.selected = []
  	// 		this.manageSelected(this.selected)
  		}
  	},
  	'selectChips':{
  		handler(newValue){
				// if newValue
		}
	},
	'selected':{
		handler(newValue){
			this.manageSelected(newValue)
		}
	},
	'perPage':{
		handler(newValue){
			this.selected = []
		}
	},
	'page':{
		handler(newValue){
			this.selected = []
		}
	},
	'allTableDescriptions':{
		handler(newValue){

		}
	},
	'tooltipObj':{
		handler(newValue){
			let _this = this
			_this.showTooltip = false
			if(newValue && newValue.valueFortooltip){
				_this.tooltipPositionX = newValue.position.positionX
				_this.tooltipPositionY = newValue.position.positionY
				_this.tooltipText = newValue.valueFortooltip
				_this.showTooltip = true
			}
		}
	},
	'hideFooter':{
			handler(newValue){

			}
		}
	},
	'gridFor':{
		handler(newValue){

		}
	},

	mounted(){
		if(this.dataList.perPage)
			this.perPage = this.dataList.perPage
		else{
			this.perPage = 10
			// this.perPage = manageScreenDisplay()
			// if(this.dataList.is_in_tab)
			// this.perPage =  this.perPage - 5
		}
		this.allHeaders = _.cloneDeep(this.dataList.headers)
		this.visibleCols = _.cloneDeep(this.dataList.headers)
		this.manageActions()
	},
	methods:{
		manageActions(){
			if(_.isEmpty(this.dataList))
				return
			var obj = _.find(this.dataList.actions,["text","new"])
			this.showAddNew = true
  			if(!obj)
  				this.showAddNew = false

  			this.getNewObjWidOpt()
		},
		manageSelected(newValue){
			debugger;
			this.dataList.selected_rows = newValue
			this.$emit('onRowSelected',newValue,this.dataList.unique_id,this.dataList.childrenDataList,this.dataList.headers)
			this.performRowHide()
			this.gridActionObject = _.cloneDeep(this.dataList)
      // logic for show icons properly.  
      this.gridActionObject.actions = []
      if(newValue.length === 0)
      	return false

			var actions = _.cloneDeep(this.dataList.actions)
			if(!actions)
				return
			// every action should have the index
			if(actions.length > 0 && !actions[0].index)
			return

			actions = _.sortBy(_.cloneDeep(this.dataList.actions),"index")


			var row = _.cloneDeep(newValue[0])
			// Every action json should contain the 'key' key.Cheack the same.
			var action_with_key = _.every(actions,'key')

			// if not every action json contain the 'key' key then return
			if(!action_with_key)
			return false

			// logic for single selected row
			if(newValue.length === 1){
			// now we have to show only that icons which has 'key' and cmp value match (if it has cmp value)
		  var valid_actions = []
		  for (var i = 0; i < actions.length; i++) {
		  	var current_action = actions[i]

	      // continue new action
	      if(current_action.text === 'new' || current_action.text === 'newWidOptions')
	      	continue

	      // grid download icon
	      if(current_action.text === 'GridDownload'){
	          // valid_actions.push(current_action)
	          continue
	        }

		    // now check key has cmpValue column
	      var key = current_action.key
	      if(row[key]){

	          // now check is that action has cmp value key
	          if(current_action.cmpValue){
	          	if(current_action['cmpValue'] === row[key])
	          		valid_actions.push(current_action)
	          	continue
	          } 
	          valid_actions.push(current_action)
	        }
	      }
	      this.ActionPanelremountTime++
	      this.gridActionObject.actions = _.cloneDeep(valid_actions)
	    }
	    else{
	    	this.gridActionObject.actions = []
	    	var multiple_actions = []
	    	for (var i = 0; i < actions.length; i++) {
	    		var current_action = actions[i]
	    		if (current_action.selectType  === 'multiple'){
	    			if(current_action['cmpValue']){
	    				let key = current_action['key']
	    				let cmpValueMatchedArray = _.filter(newValue,function(obj){
	    					return obj[key] === current_action['cmpValue']
	    				})
	    				if(cmpValueMatchedArray.length!=newValue.length)
	    					continue
	    			}
	    			multiple_actions.push(current_action)
	    		}
	    	}
	    	this.ActionPanelremountTime++
	    	this.gridActionObject.actions = _.cloneDeep(multiple_actions)
	    }
		},

		displayCriteriaPopUp(onlyAddFilters){
			var _this = this
			if(this.columnList.length === 0 && this.dataList.headers.length > 0){
				_.forEach(_this.dataList.headers,function(obj){
					let display_name = obj.text
					let extra_action = {}
					if(obj.description)
						display_name = obj.description + ' ('+obj.text+')'
					let datatype = ''
					if(JDEFormatterObj.isJDEDate(obj.column_type))
			    		datatype = 'jde_date'
			    	else if(obj.is_time_format)
			    		datatype = 'jde_time'
			    	else if(obj.is_number_format)
			    		datatype = 'jde_number'
			    	if(obj.paddingReq)
			    		extra_action = {"action":"edc_padding","paddingText":obj.paddingText,"padUpto":obj.padUpto,"padFrom":obj.padFrom}
					_this.columnList.push({'column_name':obj.text, 'display_name': display_name, "column_actual_name":obj.value,"edcdataType":datatype,'extra_action':extra_action})
				})
			}
			// if(_this.oldSelectedChips.length === _this.selectChips.length)
			if(!onlyAddFilters)
				this.showCriteria = true
		},
		getNewObjWidOpt: function(){
			this.newWidOption = _.find(this.dataList.actions, ['text', 'newWidOptions']);
			return this.newWidOption
		},
		manageCriteria(updated_criteria){
			this.updatedFilter = updated_criteria
		},
		saveCriteria(){
			this.search = ''
			var _this = this
			var search_string = ''
			_this.selectChips = []
			_this.oldSelectedChips = []

			// reshuffle the filters set by user.For example all same name columns will come as toghether with OR and different name will come as AND.
			var updatedFilter_length = _this.updatedFilter.length
			if( updatedFilter_length > 0){
				// first findout all same name columns

				// var updatedFilterCopy = _this.getRevisedFilter()


				// _this.updatedFilter = updatedFilterCopy

				_.forEach(_this.updatedFilter,function(obj,index){

					// need to add operation, incase of multiple filter
					// if(updatedFilter_length > 1 && index!=(updatedFilter_length-1)){
					// 	obj['operation'] = false // autobind and condtion.
					// }

					if(obj.column_name && obj.operator){
						search_string = _this.selectChips.push(obj.column_name + " "+ _this.getOperatorNameById(obj.operator,obj.value1,obj.value2))

						// // in case of no last row , obj.operator will be undefined
						// if(obj.operation === true || obj.operation === false)
						// {
						// 	var operation = obj.operation?" OR ": " AND "
						// 	search_string = search_string + operation
						// }

					}
				})
			}
			_this.oldSelectedChips = _this.selectChips
			_this.search = search_string
			_this.showCriteria = false
			
			let new_merged_filter_list = _this.getRevisedFilter()
			// emit updateFilter
			_this.onUpdateFilter(new_merged_filter_list)

		},
		getOperatorNameById(operator,value1,value2)
		{
			if(operator === '_eq_')
				return "= "+ value1
			if(operator === '_not_eq_')
				return "!= "+value1
			if(operator === '_lt_')
				return "< "+value1
			if(operator === '_gt_')
				return "> "+value1
			if(operator === '_lt_eq_')
				return "<= "+value1
			if(operator === '_gt_eq_')
				return ">= "+value1
			if(operator === '_is_n_')
				return "Is null"
			if(operator === '_is_nt_n_')
				return "In not Null"
			if(operator === '_sl_')
				return "like "+value1+"%"
			if(operator === '_el_')
				return "like %"+value1
			if(operator === '_cl_')
				return "like %"+value1+"%"
			if(operator === '_bet_')
				return "between "+value1+" AND "+value2
			if(operator === '_in_')
				return "in ("+value1+")"
			if(operator === '_n_in_')
				return "not in ("+value1+")"

		},
		onUpdateFilter(filter_list){
			if(!filter_list)
				filter_list = this.updatedFilter
			if(this.dataList.filterType === SERVER_SIDE){
				this.$emit('UpdateFilter', _.cloneDeep(filter_list),1,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
				this.page = 1
			}
			else
				this.onClientFilter(_.cloneDeep(filter_list))
			
			// console.log(this.updatedFilter)
		},
		setDate(obj, key, param){
			obj[key] = param;
		},
		onClientFilter(filterData){
			var new_rows = this.dataList.rows
			_.forEach(filterData,function(filterObj,index){
				let column_name = filterObj.column_name
				let operator = filterObj.operator
				let value1 = filterObj.value1
				if(!value1)
					value1 = ''
				let operation = filterObj.operation // true means OR , false means AND
				let temp_rows = _.filter(new_rows,function(rowobj){
					if(operator === '_eq_')
						return rowobj[column_name] == value1 || rowobj[column_name].toString().toLowerCase() == value1.toString().toLowerCase()
					else if(operator === '_not_eq_')
						return rowobj[column_name] != value1
					else if(operator === '_lt_')
						return rowobj[column_name] < value1
					else if(operator === '_gt_')
						return rowobj[column_name] > value1
					else if(operator === '_lt_eq_')
						return rowobj[column_name] <= value1
					else if(operator === '_gt_eq_')
						return rowobj[column_name] >= value1
					else if(operator === '_is_n_')
						return rowobj[column_name] == 'N/A'
					else if(operator === '_is_nt_n_')
						return rowobj[column_name] != 'N/A'
					else if(operator === '_sl_')
						return rowobj[column_name] && (rowobj[column_name].startsWith(value1) || rowobj[column_name].toLowerCase().startsWith(value1.toLowerCase()))
					else if(operator === '_el_')
						return rowobj[column_name] && (rowobj[column_name].endsWith(value1) || rowobj[column_name].toLowerCase().endsWith(value1.toLowerCase()))
					else if(operator === '_cl_')
						return rowobj[column_name] && (rowobj[column_name].includes(value1) || rowobj[column_name].toLowerCase().includes(value1.toLowerCase()))
				})
				new_rows = temp_rows
			})
			this.dataList.total_count = new_rows.length
			this.page = 1
			this.performClientSidePagination(new_rows)
		},
		onPageChange(number){
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPageChange', number,this.perPage,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			else
				this.performClientSidePagination()
		},
		onPerPageChange(number){
			this.page = 1
			if(this.dataList.paginationType === SERVER_SIDE)
				this.$emit('onPerPageChange', number,this.page,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			else
				this.performClientSidePagination()
		},
		onSort(sortJson){
			if(!sortJson)
				sortJson = []
			if(this.dataList.sorting_type === SERVER_SIDE){
				this.$emit('onSort', sortJson,this.dataList.table_name,this.dataList.unique_id,this.dataList.inputJson)
			}
			else {
				let sort_cols=[]
				let sort_types = []
				let sorteddatarows = []
				for (var i = 0; i < sortJson.length; i++) {
					sort_cols.push(sortJson[i].column_name)
					sort_types.push(sortJson[i].type)
				}
				if(sort_cols.length > 0){
					sorteddatarows = _.orderBy(this.dataList.rows,sort_cols,sort_types)
					this.performClientSidePagination(sorteddatarows)
				}
				else
					this.performClientSidePagination()
			}
		},
		performClientSidePagination(rows){
			if(!rows)
				rows = this.dataList.rows
			if(this.page <= 1 || rows.length < this.perPage)
				this.rowsToDisplay= rows.slice(0,this.perPage)
			else{
				let data_from = (this.page - 1)* this.perPage
				let data_to = this.page * this.perPage
				this.rowsToDisplay = rows.slice(data_from,data_to)
			}
		},
		chipDeleted(item,index){
			var _this = this
	  		/* item deleted from selection chip.
				find out index of deleted item and remove same item from the _this.updatedFilter.
				Then emit the UpdateFilter function
				*/
  				// delete from _this.updatedFilter
  				_this.updatedFilter.splice(index,1)
  				_this.selectChips.splice(index,1)
  				// remove operation key from last filter row
  				if(_this.updatedFilter.length > 0)
  					delete _this.updatedFilter[_this.updatedFilter.length - 1].operation

  				if(_this.isGridForDataviewer){
  					// remove from the inlineFilterList
  					_.remove(_this.inlineFilterList,function(obj){ return obj.column_name === item.split(' ')[0]})

  				}

  				let new_merged_filter_list = _this.getRevisedFilter()

  				// emit updateFilter
  				_this.onUpdateFilter(new_merged_filter_list)

  			},
  			getRevisedFilter(){
  				var _this = this
  				var updatedFilterCopy = []

  				var new_merged_filter_list = _.cloneDeep(_this.updatedFilter)
  				new_merged_filter_list = new_merged_filter_list.concat(_.cloneDeep(_this.inlineFilterList))

  				if(new_merged_filter_list.length === 0)
  					return updatedFilterCopy
  				var columns = _.uniq(_.map(new_merged_filter_list,"column_name"))
  				for(let i=0;i<columns.length;i++){
					var column_other_details = _.find(this.columnList,["column_name",columns[i]])
					if(new_merged_filter_list[i].isInlineFilter){
						//get operator from filter value
						let is_op = commonFuncObj.separate_operator(new_merged_filter_list[i].actual_value1, true)
						//logic for contains , start with and end with
						if (new_merged_filter_list[i].actual_value1.includes('%'))
						{
							let indx_percent = new_merged_filter_list[i].value1.indexOf('%')
							let operator_count = (new_merged_filter_list[i].value1.split("%").length - 1)
							//when count is more than 1 for % apply conatains
							if(operator_count > 1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx%']
							}
							//when index of %  is o apply ends with
							else if(indx_percent == 0){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['%xx']
							}
							//when % is last character in filter apply starts with
							else if(indx_percent === new_merged_filter_list[i]['value1'].length-1){
								new_merged_filter_list[i]['operator'] =  this.operator_to_edc_operator['xx%']
							}
							//we dont update value1 when it has edcdatatype that is jde date since value is converted in number
								if(!column_other_details["edcdataType"])
									new_merged_filter_list[i]['value1'] = ""+new_merged_filter_list[i].value1.replace('%', '')
						}
						//logic for anyother operator except %	
						else if (this.operator_to_edc_operator[is_op]){
							new_merged_filter_list[i]['operator']=  this.operator_to_edc_operator[is_op]
							if(!column_other_details["edcdataType"])
								new_merged_filter_list[i]['value1'] = new_merged_filter_list[i]['value1'].replace(is_op, '')
						}
					} 
					var dataType = ''
  					var column_actual_name = ''
  					var extra_action = {}
  					if(column_other_details){
  						column_actual_name = column_other_details.column_actual_name
  						dataType = column_other_details.edcdataType
  						extra_action = column_other_details.extra_action
  					}
  					else
  						column_actual_name = columns[i]
					var firstFilter = true // to add parenthesis on first same column filter
					for(let j=0;j<new_merged_filter_list.length;j++){
						var currentFilter = _.cloneDeep(new_merged_filter_list[j])
						
						currentFilter['s_parentheses'] = ''
						currentFilter['e_parentheses'] = ''
						delete currentFilter.operation
						if(currentFilter.column_name === columns[i]){
							if(firstFilter){
								currentFilter['s_parentheses'] = '('
								firstFilter =false
							}
							currentFilter['operation'] = true // autobind OR between same column
							currentFilter['column_name'] = column_actual_name

							if(dataType === 'date'){
								if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
									currentFilter['datedetails1']={
										format:'%Y-%m-%d',
										value:currentFilter['value1']
									}
								}
								if(currentFilter['value2']){
									currentFilter['datedetails2']={
										format:'%Y-%m-%d',
										value:currentFilter['value2']
									}
								}
							}
							else if(dataType === 'jde_date'){
								if(currentFilter['value1'] && !currentFilter['isInlineFilter']){
									if(currentFilter['v_type1'] === 'Date'){
										let jdeDate = getJDEDate(currentFilter['datedetails1']['actual_date'],currentFilter['datedetails1']['format'])
										
										currentFilter['value1'] = jdeDate
										currentFilter['datedetails1']= {}
										currentFilter['v_type1'] = "Value"
									}
									else{
										let jdeDate = getJDEDate(currentFilter['value1'])
										if(!jdeDate)
											continue
										currentFilter['value1'] = jdeDate
									}
								}
								if(currentFilter['value2']){
									if(currentFilter['v_type2'] === 'Date'){
										let jdeDate =  getJDEDate(currentFilter['datedetails2']['actual_date'],currentFilter['datedetails2']['format'])
										if(!jdeDate)
											continue
										currentFilter['value2'] = jdeDate
										currentFilter['datedetails2']= {}
										currentFilter['v_type2'] = "Value"
									}
									else{
										let jdeDate = getJDEDate(currentFilter['value2'])
										if(!jdeDate)
											continue
										currentFilter['value2'] = jdeDate
									}
								}
								
							}
							else if(dataType === 'jde_time'){
								currentFilter['value1'] = JDEFormatterObj.getJDETime(currentFilter['value1'])

								currentFilter['value2'] = JDEFormatterObj.getJDETime(currentFilter['value2'])
							}
							else if(dataType === 'jde_number'){
								currentFilter['value1'] = commonFuncObj.replaceText(currentFilter['value1'])
								currentFilter['value2'] = commonFuncObj.replaceText(currentFilter['value2'])
							}
							if(extra_action.action && extra_action.action === 'edc_padding'){
								currentFilter['value1'] = JDEFormatterObj.addPadding(currentFilter['value1'],extra_action.paddingText,extra_action.padUpto,extra_action.padFrom)
							}
							updatedFilterCopy.push(currentFilter)
						}
					}
					if(updatedFilterCopy.length > 0){
					updatedFilterCopy[updatedFilterCopy.length - 1]['operation'] = false // autobind AND between two column
					updatedFilterCopy[updatedFilterCopy.length - 1]['e_parentheses'] = ')'
				}
			}
			// remove operation from last filter
			delete updatedFilterCopy[updatedFilterCopy.length - 1].operation
			return updatedFilterCopy
		},
		updateTableOptions(options){
			var sortByJson = []
			if(options.sortBy.length > 0 && options.sortDesc.length > 0){
				this.clickOnSort = true
				for (var i = 0; i<options.sortBy.length; i++) {
					let data = {}
					data['column_name'] = options.sortBy[i]
					data['type'] =options.sortDesc[i]? "desc":"asc"
					sortByJson.push(data)	
				}
			}
			let sortChanged = this.isSortChange(this.previousSort,sortByJson)
			if(!sortChanged)
				return
			this.previousSort = _.cloneDeep(sortByJson)
			this.onSort(sortByJson)
		},
		isSortChange(oldSort,newSort){
			if(oldSort.length != newSort.length)
				return true
			for(var i=0;i<oldSort.length;i++){
				let currentOldSort = oldSort[i]
				let keys = Object.keys(currentOldSort)
				let currentNewSort = newSort[i]
				for(var j=0;j<keys.length;j++){
					let key = keys[j]
					if(currentOldSort[key] != currentNewSort[key])
						return true
				}
			}
			return false
		},
		hideDataGridColumns(){
			var dnone = this.hideColProp
    	// this.dataList.headers = []
    	for(let i=0;i<this.dataList.headers.length;i++){
    		if(this.dataList.headers[i]['align'] != dnone)
    			this.dataList.headers[i]['default_align'] = this.dataList.headers[i]['align']? this.dataList.headers[i]['align'] : 'left'
    		let obj = _.find(this.visibleCols,["text",this.dataList.headers[i].text])
    		if(!obj){
    			this.dataList.headers[i]['align'] = dnone
    		}
    		else{
    			if(this.dataList.headers[i]['default_align'])
    				this.dataList.headers[i]['align'] = this.dataList.headers[i]['default_align']
    		}
    	}
    },
    getFormatedData(header,text,item,is_key_json){
    	if(!text && text != 0){
    		if(is_key_json){
    			let actual_key = header.value
    			let keys = actual_key.split('.')
    			/* Here I am considering only two keys for timebieing*/
    			text = item[keys[0]][keys[1]]
    		}
    		else
    			return text
    	}
    	// this specific validation is to remove transaction id from row count column in sql grid
    	if(header.validate === 'number'){
    		if(isNaN(text))
    			return ''
    	}
    	if(!this.dataList.useWrapping)
    		return text
    	let new_text = text
    	let isDate = false
    	if(!isNaN(text)){
    		if(JDEFormatterObj.isJDEDate(header.column_type))
    		{
    			// its juliean date. Now need to convert into normal date

    			let days = (text%1000) - 1
    			let years = parseInt(text/1000) + 1900
    			let convertedDate = moment(years+"01-01","YYYY-MM-DD")
    			new_text = convertedDate.add('days',days).format("YYYY-MM-DD")
    			header['align'] = 'left'
    		}
    		return new_text
    	}
    	let header_text = header.text
    	new_text = new_text.replace(/\n/g, '').trim()
    	if((header_text.length+2) < new_text.length){
    		let string_to_return = new_text.substring(0,header_text.length-1)
    		return string_to_return+'..'
    	}

    	// if((new_text.length+2) > 12){
    	// 	let string_to_return = new_text.substring(0,11)
    	// 	return string_to_return+'..'
    	// }
    	else
    		return new_text
    },
    getColTooltips(headertext,header){
    	let _this = this
    	if(header.is_attachment_col)
    		return "Attachments"
    	let description = ''
    	let obj = _.find(_this.dataList.headers,["text",headertext])
    	if(obj && obj.title)
    		return obj.title.toUpperCase()
    	return headertext.toUpperCase()
    },
    onDownload(downloadParams){
    	this.$emit('onDownload',downloadParams,this.dataList.unique_id,_.cloneDeep(this.dataList),_.cloneDeep(this.selected))
    },
    createEvent(recordType,eventName, defaultVal){
    	let record=(recordType == 'single') ? _.cloneDeep(this.selected[0]):this.selected;
    	if(eventName == 'onEdit' && this.dataList.is_grid_inline_edit){
    		let key = this.dataList.itemkey
    		let obj = _.find(this.dataList.rows,[key,record[key]])
    		if(obj){
    			// let index = _.indexOf(this.dataList.rows,obj)
    			obj.is_row_editable = true
    			this.AddRowForGridEditing(obj)
    			this.$emit('onValueUpdate',record)
    		}

    	}
    	else {
    		this.$emit(eventName, record, defaultVal);
    		if(this.dataList.is_grid_inline_edit) // to overcome select all rows default.Please check if condition in datalist.row and rowTodisplay watch
    			this.selected = []
    	}
    },
    onCancelEditing(record){  
    	this.$emit('onCancel', record);
    	this.selected = []
    },
    getColor(chipFor,value){
    	if(!chipFor || !value)
    		return 'grey'
    	if(chipFor === 'status'){
    		return GRID_STATUS_COLOR_CODE[value.toLowerCase()]
    	}
    },
    validateInput(event,inputType){
    	if(!inputType)
    		return true
    	var keycode = event.which
    	if(inputType === 'number'){
    		if(isValidNumber(keycode))
    			return true
    		
    	}

    	event.preventDefault()
    },
    radioSelectionChange(record){
    	this.$emit('radioSelectionChange', record);
    },
    copyToClipboard(event,header){
    	if(!header.copyToClipboard)
    		return
    	var source = event.srcElement
    	var textArea = document.createElement("textarea");
    	textArea.value = source.textContent;
    	document.body.appendChild(textArea);
    	textArea.select();
    	document.execCommand("Copy");
    	textArea.remove();
    },
    changeAutoCompleteSelection(event_value,item,header,headerindex,itemindex){
    	this.AddRowForGridEditing(item)
		this.$emit('onValueUpdate', event_value, item, header, headerindex, itemindex)
	},
		AddRowForGridEditing(item){
			if(this.dataList.hideSelect)
				return
			this.$nextTick(function () {
				this.$set(this.selected, 0, item);
			})
		},
		rowDoubleClick(record,recordIndex){
			if(!this.dataList.is_grid_inline_edit)
				this.$emit('ondblClick',record,recordIndex)
			else
			{
				this.selected = [record]
				this.createEvent('single','onEdit')
			}	
		},
		rowClick(event,row,rowIndex){
			let _this = this
			this.contextMenuList = []
			this.contextCombineMenuList = []
			if(!this.dataList.showContextMenu || !this.dataList.children || !this.dataList.children.length || !this.selected.length)
				return

			if(this.dataList.children && this.dataList.children.length){
				let menuList = _.cloneDeep(this.dataList.children)
				let copiedObj = {}
				menuList.forEach(obj=>{
					obj.display_name = obj.name
					let descriptionObj = _.find(_this.allTableDescriptions,["title",obj.name])
					if(descriptionObj)
						obj.display_name = descriptionObj.description + ' ('+obj.name+')'
					copiedObj = _.cloneDeep(obj)
				})

				// Here we will add 2 more options. 
				// 1st is All
				let combinedContextMenu = []
				if(menuList.length > 1){

					let allMenuObj = _.cloneDeep(copiedObj)
					allMenuObj.title = "all"
					allMenuObj.display_name = "Load all children"
					allMenuObj.node_id = 'all'
					allMenuObj.parent_id = 'all'
					allMenuObj.children = []
					allMenuObj.isMultiple = true
					allMenuObj.table_name = 'all'
					combinedContextMenu.push(allMenuObj)
				}

				// 2nd is load all opened childs.
				if(_this.dataList.childrenDataList && _this.dataList.childrenDataList.length){
					let onlyOpenedMenuObj = _.cloneDeep(copiedObj)
					onlyOpenedMenuObj.title = "open"
					onlyOpenedMenuObj.display_name = "Reload all open children"
					onlyOpenedMenuObj.node_id = 'open'
					onlyOpenedMenuObj.parent_id = 'open'
					onlyOpenedMenuObj.children = []
					onlyOpenedMenuObj.isMultiple = true
					onlyOpenedMenuObj.table_name = 'open'
					combinedContextMenu.push(onlyOpenedMenuObj)
				}

				this.contextMenuList = menuList
				this.contextCombineMenuList = combinedContextMenu
			}
			_this.$nextTick(()=>{
				if(_.indexOf(_this.selected,row)<0)
					_this.selected.push(row)
			})
			// this.$refs.ctxMenu.open()
			this.showMenu = false
	        this.menuPositionX = event.clientX
	        this.menuPositionY = event.clientY
	        this.$nextTick(() => {
	          this.showMenu = true
	        })
		},
		contextMenuClick(item){
			let _this = this
			let childDetails = _.cloneDeep(item)
			let loadType = ''
			_this.compressVeritcalSpace = true
			_this.rowsToDisplay = _this.selected
			if(item.isMultiple){
				if(item.title === 'all'){
					loadType = 'multiple'
					childDetails = _.cloneDeep(_this.dataList.children)
				} else {
					loadType = 'recursive'
					childDetails = _.cloneDeep(_this.dataList.childrenDataList)
				}
			}
			this.$emit('contextMenuClick',childDetails,this.selected,loadType,_this.dataList.headers)
		},
		getIndeterminateState(header,item){
			// if(!hea)
		},
		setDisabled(header,item){
			if(!this.dataList.caption)
				return false
			if(this.dataList.caption ==="adminRole" || this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
				let service_actions = item.service_actions
				if(header.text === 'All')
					return false
				if(service_actions.indexOf(header.value)>-1)
					return false
			}
			return true
		},
		checkChanged(event_value,header,item){
			if(!header.toggleRow || !this.dataList.caption)
				return
			if(this.dataList.caption ==="adminRole"||this.dataList.caption ==="envRole" || this.dataList.caption ==="notificationGroup"){
					let service_actions = item.service_actions
				if(header.text === 'All'){
					if(!event_value) // this event_value come as a null.make it proper boolean
						event_value = false
					service_actions.forEach(function(obj){
						item[obj] = event_value
					})
					return
				}
				else{
					let atleastOneChecked=false
					let atleastOneUnchecked=false
					// let obj = _.find(this.dataList.rows,["text","All"])
					// if(!obj)
					// 	return
					service_actions.forEach(function(obj){
						if(!atleastOneChecked){
							if(item[obj])
								atleastOneChecked = true
						}
						if(!atleastOneUnchecked){
							if(!item[obj])
									atleastOneUnchecked = true
						}
					})
					// if(!atleastOneChecked && !atleastOneUnchecked){
					// 	item.useIndeterminateState = false
					// }
					// if(atleastOneChecked && atleastOneUnchecked)
					// {
					// 	item.useIndeterminateState = true
					// }
					item.all = false
					if(atleastOneChecked)
					{
						let default_actions = item.default_actions
						default_actions.forEach(function(obj){
							item[obj] = true
						})
					}
					if(atleastOneChecked && !atleastOneUnchecked){
						item.all = true
					}

				}
			}
		},
		applyInlineFilter(event,header,filtertext){
			if(!filtertext)
				return
			let column_name = header.value

			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
			})

			let filterjson= {"column_name":header.text,"column_actual_name":header.value,"value1":filtertext,"operator":"_cl_","hideKey":true,'isInlineFilter':true}
			if(header.dataType === 'number' || header.dataType === 'date' || header.dataType === 'boolean'){
				filterjson['operator'] = "_eq_"
			}
			let revisedFilterText = filtertext
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				let jdeDate = getJDEDate(filtertext,this.$session.get('UI_date_format'))
				if(!jdeDate)
					return
				revisedFilterText = jdeDate
				filterjson['value1'] =  revisedFilterText
			}
			if(header.dataType === 'date'){
				filterjson['datedetails1']={
					format:this.$session.get('date_format'),
					value:revisedFilterText
				}
			}
			if(commonFuncObj.isNumericDataType(header.datatype)){
				filterjson['operator'] = "_eq_"
			}
			if(header.paddingReq){
				header.filtertext = JDEFormatterObj.addPadding(header.filtertext,header.paddingText,header.padUpto,header.padFrom)
			}
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				filterjson['actual_value1'] = convertToUserNativeDate(header.filtertext,this.$session.get('UI_date_format'))
			}
			else
				filterjson['actual_value1'] = header.filtertext

			this.displayCriteriaPopUp(true)
			// this.updatedFilter.push(_.cloneDeep(filterjson))
			this.inlineFilterList.push(_.cloneDeep(filterjson))
			// header.filtertext = ""
			this.saveCriteria()
		},
		clearInlineFilter(event,header,filtertext){
			let column_name = header.value
			let value1 = filtertext
			// let removed_obj = _.remove(this.updatedFilter,function(obj){
			// 	return obj.column_name === column_name && obj.value1 === value1
			// })
			let removed_obj = _.remove(this.inlineFilterList,function(obj){
				/*committed because this not working for date filter*/
				// return obj.column_actual_name === column_name && obj.value1 === value1

				return obj.column_actual_name === column_name
			})
			header.filtertext = ''
			this.saveCriteria()
		},
		keyUpInlineFilter(event,header,filtertext){
			// 8 backspace, 46 delete
			if((event.keyCode === 8 || event.keyCode===46) && !filtertext){
				let column_name = header.value
				let removed_obj = _.remove(this.inlineFilterList,function(obj){
				return obj.column_actual_name === column_name
			})
			this.saveCriteria()
			}
		},
		isChipShow(index){
			if(this.updatedFilter.length < index || this.updatedFilter[index].hideKey)
				return false
			return true
		},
		manageHeaderText(showDescriptionAsHeader){
			let _this = this
			if(!this.dataList.headers.length)
				return
			if(showDescriptionAsHeader){
				_this.dataList.headers.forEach(obj=>{
					obj['displayHeader'] = obj['description']
					obj['title'] = obj['text']
				})
			}
			else {
				_this.dataList.headers.forEach(obj=>{
					obj['displayHeader'] = obj['text']
					obj['title'] = obj['description']
				})
			}
		},
		showSummaryToggleChanged(value){
			// false is for show summary. Keep this way because of on/off functionality of table button
			if(value){
				this.$emit('loadSummaryFooter',this.dataList.unique_id)
			}	
		},
		MouseOverEvent(obj){
			obj.target.style.backgroundColor = "grey";
		},
		MouseOutEvent(obj){
			obj.target.style.backgroundColor = "white";
		},
		mouseEnterOnTd(event,header,item_value,item_row){
			let _this = this
			_this.showTooltip = false
			// clearInterval(this.tooltipTimeout)
			// _this.tooltipTimeout = setTimeout(function(){
			// 	_this.tooltipPositionX = event.x
			// 	_this.tooltipPositionY = event.y
			// 	_this.tooltipText = item_value
			// 	_this.showTooltip = false
			// },_this.tooltipDelay)
			let position = {"positionX":event.x,"positionY":event.y}
			let table_name = _this.dataList.table_name
			_this.$emit('mouseEnterOnTd',table_name,header,item_value,item_row,position,_this.dataList.unique_id)
			
		},
		mouseLeaveTd(event){
			let _this = this
			_this.$emit('mouseLeaveTd',_this.dataList.unique_id)
			
		},
		copySelectedRowsToClipboard(event){
			if(!this.selected.length)
				return
			let _this = this
			let copiedString = _.map(this.dataList.headers,'displayHeader').join(',')
			let headersKey = _.map(this.dataList.headers,'value')
			for(let i=0;i<this.selected.length;i++){
				copiedString =copiedString + ' \n '+this.getItemValues(this.selected[i],headersKey)
			}
			// _this.$copyText(copiedString).then(function (e) {
			// 	_this.$emit('rowCopyToClipboard','Selected rows copied to clipboard!!','success')
	        //   console.log(e)
	        // }, function (e) {
	        //   _this.$emit('rowCopyToClipboard','error in copying the text!!','error')
	        //   console.log(e)
	        // })
		},
		getItemValues(array,headers_key,seprator=','){
			let stringValue = ''
			if(!headers_key)
				headers_key = Object.keys(array)
			headers_key.forEach((key)=>{
				if(stringValue)
					stringValue = stringValue + ','+array[key]
				else
					stringValue = array[key]
			})
			return stringValue
		},
		checkboxSelectionChange(){
			let _this = this
			_this.$emit('onRowSelected',_this.selected,_this.dataList.unique_id,_this.dataList.childrenDataList,_this.dataList.headers)
			_this.performRowHide()
		},
		performRowHide(){
			let _this = this
		    clearInterval(_this.dataList.hideRowTimer);
			if(_this.dataList.hideNonSelectedRows){
				if(_this.selected.length && _this.dataList.childrenDataList.length){
					if(!_this.dataList.hideRowDelay)
						_this.dataList.hideRowDelay = 3000
					_this.dataList.hideRowTimer = setTimeout(function(){
						_this.compressVeritcalSpace = true
						_this.rowsToDisplay = _this.selected
					},_this.dataList.hideRowDelay)
				}
				else{
					_this.compressVeritcalSpace = false
					_this.rowsToDisplay = _this.dataList.rows
				}

			}
		},
		onClickAdvanceFilter(header){
			this.$emit('onClickAdvanceFilter',header,this.dataList.unique_id)
		},
		getInlineFilterPlaceHolder(header){
			if(JDEFormatterObj.isJDEDate(header.column_type)){
				return 'dd/mm/yyyy'
			}
			else 
				return ''
		},
		addInlineFilterText(inlineFilterDetails){
			let header = _.find(this.dataList.headers,['text',inlineFilterDetails.column_name])
			if(header){
				header.filtertext = inlineFilterDetails.filtertext
				return this.applyInlineFilter({},header,inlineFilterDetails.filtertext)
			}
		},
		getSummaryFooterValue(header){
			if(_.isEmpty(this.dataList.summaryFooter) || !this.dataList.summaryFooter[header.value])
				return false
			return this.dataList.summaryFooter[header.value]
		},
		loadDiscriptionToggleTitle(){
			if(this.headerToggle)
				return "Show Description"
			return "Show Columns"
		},
		changeGridView(action){
			if(!this.selectedView)
				return
			this.$emit('changeGridView',this.dataList,this.selectedView,action)
		},
		clickOnViewIcons(clickType){
			this.changeGridView(clickType)
		},
		viewSelectionChanged(){
			this.changeGridView()
		},
		autoFillupInlineFilter(filter_data){
			// Inline column filter does not retain the value after the GridView is changed.  To reproduce: Open F0911 using default view Enter 6188 for Batch Number in inline column filter Change GridView (ex: Totals by Fiscal Period by Account).  This will correctly display the selected details for batch 6188. Change GridView to default.  This will correctly display records for batch 6188; however, there is no value in the inline filter.  This makes it appear there is no filter applied.

			// logic is show filter in inline text in header if filter column is part of the column selection else show in chips [{"column_name":"phdoco","column_actual_name":"phdoco","value1":"4899","operator":"_eq_","hideKey":true,"isInlineFilter":true,"s_parentheses":"(","e_parentheses":")"}]
			let _this = this
			_this.selectChips = []
			_this.updatedFilter = []
			_this.inlineFilterList = []
			for(let i=0;i<filter_data.length;i++){
				filter_data[i].column_name = filter_data[i].column_name.toUpperCase()
				let column_name = filter_data[i].column_name.toLowerCase()
				let colObj = _.find(_this.dataList.headers,["value",column_name])
				if(_.isEmpty(colObj))
					colObj = _.find(_this.dataList.headers,["value",column_name.toUpperCase()])
				if(!_.isEmpty(colObj)){
					_this.inlineFilterList.push(filter_data[i])
					colObj.filtertext = filter_data[i].value1
					if(filter_data[i].actual_value1)
					colObj.filtertext = filter_data[i].actual_value1
				}
				else{
					filter_data[i]['operation'] = true
					this.updatedFilter.push(filter_data[i])
					_this.selectChips.push(filter_data[i].column_name + " "+ _this.getOperatorNameById(filter_data[i].operator,filter_data[i].value1,filter_data[i].value2))
				}
			}
			if(_this.updatedFilter.length)
				delete _this.updatedFilter[_this.updatedFilter.length - 1].operation
		},
		loadMediaAttachmentDetails(index,attachment_details){
			this.$emit('loadMediaAttachmentDetails',this.dataList.unique_id,attachment_details)
		},
		onCleareAllInlineFilter(){
			for(let i=0; i < this.dataList.headers.length; i++){
				this.dataList.headers[i].filtertext = ""
			}
			this.inlineFilterList = []
			this.saveCriteria()
		},
		getIndentedLVL(value){
			if (!value || isNaN(value))
				return value
			else
			{
				let i=0;
				let indentedValue = value.toString()
				while (i < value-1){
					indentedValue = "    "+indentedValue
					i++
				}
				return indentedValue
			}
		}
	}
}
</script>
<style>
th{ 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow:ellipsis;
	font-size: 12px !important;
} 

td{ 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow:ellipsis;
	font-size: 12px !important;
} 

tbody tr:nth-of-type(even) {
	background-color:var(--v-datatablealternate-base) !important;
}

.v-pagination__item{
	font-size: 12px !important;
}

.EDCTableFooter {
	font-size: 12px !important;
}

.v-select__selection{
	font-size: 12px !important;
}
td {
	border:none !important;
}

.EDC-VCardColumn{
	height: 60px !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
	overflow: none !important;
}

.EDC-VCombobox{
	display: inline-block !important;
	padding: 0px !important;
	margin: 0px !important;
	width: 250px !important;
}

.v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
}

/*.v-text-field {
	padding-top: 0px !important; 
	margin-top: 0px !important;
}*/

table {
	border-top:1px var(--v-secondary-lighten3) solid;
}

table {
	color:var(--v-apptext-base) !important;
}
table .theme--light.v-icon{
	color:var(--v-primary-base) !important;
}

.hideCol {
	display:none;
}

.keep-spaces { white-space: pre-wrap; }

/*
*  STYLE 2
*/

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1) !important;
	border-radius: 10px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar
{
	width: 5px !important;
	background-color: white !important;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	background-color: #DCDCDC !important;

}

.scrollbar
{
	background: white !important;
	overflow-y: hidden !important;
	margin-bottom: 25px !important;
	overflow-x:  scroll !important;
}
.v-sheet {
	border-radius: 5px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot{
	padding-left:3px !important;
	padding-top:0px !important;
	padding-right:3px !important;
	padding-bottom:0px !important;
	height:20px !important;
}

.v-text-field--rounded {
	border: 2px solid;
    border-radius: 5px;
    border-color: var(--v-primary-base) !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-text-field__slot {
	min-width:16px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner{
	margin-top:0px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon{
	height:20px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot > .v-input__append-inner> .v-input__icon > .v-icon.v-icon{
	font-size:16px !important;
}
.v-list-item {
	text-align:start !important;
}
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 24px;
}

.singleLine {
	display: inline-block;
	white-space: nowrap;
}

.right_aligned_text input {
	text-align: right !important;
}

.summary-text-field.v-input--dense>.v-input__control>.v-input__slot:before {
    border-style: none !important;
    border-top-style: solid;
    border-top-width: thin;
}

.summary-text-field.v-input--dense>.v-input__control>.v-input__slot:after {
    border-style: none !important;
    border-top-style: solid;
    border-top-width: thin;
}
.summary-text-field.v-input--dense>.v-input__control>.v-input__slot{
	border-top-style: solid;
    border-top-width: thin;
}

.header-summetion{
  content: "\2211";
}
</style>