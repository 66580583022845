import config from '../config.json'
import { post as postToServer } from './serverCall.js';
export function getLayoutCardObject(slotNumber){
    return {"draggedWidgetId":"","slotNumber":slotNumber,"widget_modifiers":{}}
}

function applicationVariableMapping(that){
    let variableObj = {
        "environment_id":that.$session.get("last_selected_env_id"),
        "client_id":that.$session.get("client_id"),
        "user_id":that.$session.get("email")
    }
    return variableObj
}

function serviceMappings(serviceId){
    let serviceObj = {
        "publisher_service":config.PUBLISHER_URL,
        "environment_setting_service":config.ENVIRONMENT_API_URL,
        "edc_gateway_service":config.GATEWAY_API_URL
    }
    return serviceObj[serviceId]
}


export function manageInputParams(that,inputData,modalValue){
    let inputDataKeys = _.keys(inputData)
    for(let i=0;i<inputDataKeys.length;i++){
        let sysKey = inputData[inputDataKeys[i]]
        let sysVal = sysVariableMapping(that,sysKey)
        if(sysVal)
            inputData[inputDataKeys[i]] = sysVal
        else if(_.isObject(modalValue) && !_.isEmpty(modalValue)){
            processModelValue(inputDataKeys[i],inputData,modalValue)
        }            
    }
    return inputData
}

export function parseInputParams(_this, params, allComponentSelectedValues={}) {
    /* 
    params is a list of JSON objects and will look like this:
    [
        { 
            "paramKey": "envId",           // The key to pass in the final inputData object
            "sourceType": "application",   // From where to fetch the session/application data
            "sourceValue": "environment_id", // Key to use to fetch the data from the application/session data
            "is_required": true            // Indicates whether the value is required
        },
        { 
            "paramKey": "datasource_id",   // The key to pass in the final inputData object
            "sourceType": "control",       // Indicates the value comes from a control (e.g., dropdown)
            "controlKey": "datasource",    // The control key to look for in allComponentSelectedValues
            "sourceValue": "selectedValue.datasource_id", // Nested key to extract data from the control's selectedValue
            "is_required": true            // Indicates whether the value is required
        },
        { 
            "paramKey": "filter",   // The key to pass in the final inputData object
            "sourceType": "",
            "sourceValue":[]
        }
    ]
    */
   
    // Fetch application/session variables using the mapping function
    let applicationMapping = applicationVariableMapping(_this);
    
    // Initialize inputData object that will store final key-value pairs
    let inputData = {};

    // Iterate over each param object in the params array
    for (var i = 0; i < params.length; i++) {
        let currentParams = params[i];  // Get the current parameter object
        
        // Check if the source of the value is from the application/session data
        if (currentParams.sourceType === "application") {
            // If the application variable is not found in the mapping, throw an error
            if (!applicationMapping[currentParams.sourceValue]) {
                let message = "No application value configured for " + currentParams.sourceValue;
                alert(message);  // Alert the user about the missing application data
                throw message;   // Stop execution by throwing the error
            }
            // If the application value is found, add it to inputData
            inputData[currentParams.paramKey] = applicationMapping[currentParams.sourceValue];
        
        // Check if the source of the value is from a control (e.g., dropdown or form element)
        } else if (currentParams.sourceType === "control") {
            // If the control's selected value is not found, throw an error
            if (!allComponentSelectedValues[currentParams.controlKey]) {
                let message = "No component selected value found for " + currentParams.controlKey;
                  // Alert the user about the missing component value
                throw message;   // Stop execution by throwing the error
            }
            
            // Get the selected value from the control (e.g., dropdown)
            let componentData = allComponentSelectedValues[currentParams.controlKey];

            // Check if the control has a nested key in its selectedValue
            if (currentParams.sourceValue) {
                // Extract the nested key from sourceValue (e.g., "selectedValue.datasource_id" becomes "datasource_id")
                let key = currentParams.sourceValue.replace("selectedValue.", '');
                // Add the value to inputData by accessing the key inside selectedValue
                inputData[currentParams.paramKey] = componentData['selectedValue'][key];
            } else {
                // If no nested key is provided, directly add the control's selectedValue to inputData
                inputData[currentParams.paramKey] = componentData['selectedValue'];
            }
        } else{
            // means there are hardcoded constants
            inputData[currentParams.paramKey] = currentParams['sourceValue'];
        }
    }

    // Return the final inputData object containing all key-value pairs
    return inputData;
}



export async function makeAPICall(that,APIJson,inputData){
    let endPoint = APIJson.endpoint
    let url = serviceMappings(APIJson.service_id)+endPoint
    let apiResponse = await postToServer(that,url,inputData)
    return apiResponse
}


export async function getDashboardOfUser(_this,envDetails){
    if(_.isEmpty(envDetails))
        return
    _this.$session.set('selected_env',envDetails)

    var data = {
        "username":_this.userName,
        "client_id": _this.clientId,
        "env_id":envDetails.id
    }
    var url = config.USER_PROVISION_URL + "/get_user_dashboard"
    let apiResponse = await postToServer(_this, url,data)
    return apiResponse
}