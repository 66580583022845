<template>
	<div id="datamapdesigner">
		<v-container class="EDC-Row breadcrumbBackground">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col text-truncate" align="left" cols="11">

					<ul class="breadcrumb" style="display:inline-flex;padding-left:0px !important;">
						<li>{{env_name}}</li>
						<li>
							<input type="text" v-model="datamapname" v-on:focus="oldDMName=datamapname" v-on:blur="OnNameChange()" :readonly="isReadOnly" :size="nameTextboxSize">
						</li>
					</ul>
					<span style="color:rgba(0,0,0,0.90);font-weight:400;font-size:13px;margin-left:5px;" v-if="data_map_id">
						<span style="cursor: pointer;" @click="ShowObjDetails">
							Ver. {{object_revision}}
						</span>

		        <!-- <svgicon v-if="!isReadOnly" class="svg-icon-grid svg-fill-grid" name="New" @click="createNewVersion" width="16" height="16" :original="true" title="Create New Version"></svgicon> -->

					</span >
					<span v-if="!isReadOnly" style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
						{{'('+savedstatus + ')'}}
					</span>

					<span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
					(Readonly Mode)</span>

					<span v-if="!isReadOnly" style="padding-left:4px !important;">
						<toggle-button class="saveTypeToggle" :labels="{checked: 'Auto Save', unchecked: 'Auto Save'}" v-model="isAutoSave" style="margin-top:0px !important;margin-bottom:0px !important;border-radius:20px !important;padding-right:2px !important;" :sync="true" :width="96" :color="{checked: '#75C791', unchecked: '#859AA4', disabled: '#CCCCCC'}" @change="setUserPreferences"/>
						<!-- <vc-button itemText="Save" :xSmall='true' :disabled="!saveType"></vc-button> -->
					</span>

					<span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
				</v-col>

				<v-col class="EDC-Col" cols="1">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col"></v-col>
						<v-col class="EDC-Col" cols="4">
							<svgicon v-if="!validateProgress" class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:1px;"  title="Validate Object" @click="Validatedatamapobj"></svgicon>
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
							<showerrorlisttemplate :bovalidationresponse="validation_response"
		        			@totalCount="errorListCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" errorsource="bo" :bographdata="bographdata" @setErrorOnBO="setErrorOnBO" style="margin-top:2px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate>
						</v-col>

						<v-col class="EDC-Col" cols="4">
							<svgicon class="svg-icon-grid svg-fill-grid" name="cancel_v2"  @click="showClosePopUp()" style="float:right;"  title="Close Tab"></svgicon>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" align="left">
					<edc-graph-toolbar :redoCount="undoStack.length" :undoCount="redoStack.length" :showAutoArrange="true"
					 @performUndoRedo="keyupHandler" @showDescription="showDescription()" 
					 @autoArrangeBusinessObject="autoArrangeBusinessObject"  @createNewVersion="createNewVersion" 
					 @onSave="toolbarSaveClicked" @templateOptionChanged="templateOptionChanged" :isTemplate="isTemplate" :erpCode="erp_code"></edc-graph-toolbar>
				</v-col>
			</v-row>
		</v-container>
		<v-layout raw wrap>
			<tablelistpanel :tableListObj="tablelistpanelproperties" :boListObj="business_obj_list" @dragged-object="draggedObject" :container-height="graphHeight" @performcollapse="perform_collapse" graphFor="data_map"  :businessObjList="business_obj_list" :readonly="isReadOnly">

			</tablelistpanel>
			<!-- <tablelistpanel style="width:20% !important;"></tablelistpanel> -->
			<div style="width:80%" id="main_holder" ref="main_holder">
				<div id="main_container" ref="main_container" :style="{'height':graphHeight}" style="width:100%;">
					<edc-graph :mainPaperDimentions="paperDimentions" :newObject="newCell" @sendLinkEvent="receiveLinkEvent" @sendElementEvent="receiveElementEvent"
					@sendCellEvent="receiveCellEvent" @sendPaperEvent="receivePaperEvent" @sendGraphEvent="receiveGraphEvent" graphFor="data_map"
					:businessObjectShapeActions="businessObjectShapeActions" :graphLoader="tableListLoader" :propChangedFor="propChangedFor" :cellToRemove="cellToRemove"
					:dataForGraphRedesign="dataForGraphRedesign" :validationGraphObj="validationGraphObj" :isReadOnly="isReadOnly"
					@loadTableDescription='loadTableDescription' :tableDescriptionList="tableDescriptionList"
					:copyJoinJson="copyJoinJson" :autoArrangeGraph="autoArrangeGraph" @sendNonGraphEvent="pushForUndo"></edc-graph>
				</div>
			</div>
		</v-layout>
		<simplert :useRadius="true"
           :useIcon="true"
           ref="simplert">
        </simplert>

		<v-flex xs12>
			<v-dialog v-model="filter_dialog" eager width="920" scrollable style="overflow-y:none" persistent>
				<v-flex>
					<v-card class="rounded-card" style="height: 325px;">
						<v-toolbar dark dense>
							<v-flex class="text-md-center">Filter for table <b>{{filtertablename}}</b>
								<v-icon class="text-lg-left" @click="closeFilter('close')" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
							</v-flex>
						</v-toolbar>
						<v-flex pa-2 xs12 style="height: 225px; overflow-y: scroll;" id="step_detail_table">
							 <new-edc-criteria :filtertablename="filtertablename" :columns_for_filter="filter_rows_list" :column_name_list="avlColumnList"
							 @savedata="saveCriteria" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :readonly='isReadOnly'></new-edc-criteria>
						</v-flex>
						<vc-button outline itemText="Save"  @click.native="closeFilter('save')" :disabled="isReadOnly">
                		</vc-button>
					</v-card>
				</v-flex>
			</v-dialog>
			<v-dialog v-model="tbl_dialog" min-width="400" max-width="650" scrollable style="overflow-y:none" persistent>
				<v-flex>
					<v-card class="rounded-card" style="height:100%">
						<v-form ref="form" @submit.prevent="performcolsselection()">
							<v-toolbar dark dense>
								<v-flex class="text-md-center">
									{{dbl_click_tbl_cell_name}}
									<v-icon class="text-lg-left" @click="performcolsselection()" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
								</v-flex>
							</v-toolbar>
							<v-flex pa-2 xs12 style="overflow-y: scroll;" id="step_detail_table">
								<edc-data-grid :dataList="columnDatatableList" ></edc-data-grid>
							</v-flex>
							<vc-button type="submit" itemText="Save" :disabled="isReadOnly"></vc-button>
						</v-form>
					</v-card>
				</v-flex>
			</v-dialog>
			<v-dialog v-model="link_dialog" width="900" persistent>
				<v-flex>
					<v-card class="rounded-card">
						<v-toolbar class="EDC-Toolbar" dense elevation="1" style="border-radius:0px !important;">
							<v-toolbar-title class="EDC-ToolbarTitle">Relationship</v-toolbar-title>

      						<v-spacer></v-spacer>
							<svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="close_join_dialog(true)" title="Close"></svgicon>
						</v-toolbar>
							<v-form ref="form2" @submit.prevent="close_join_dialog()">
								<v-container class="EDC-Container EDC-ContainerPadding">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="3" align="left">
											<label class="v-label" style="margin-top: 3%;text-align: left;">Relationship Type</label>
										</v-col>
										<v-col class="EDC-Col" cols="1" v-for="n in link_relationshiplist" :key="n.id" align="left">
											<v-radio-group name="relationshipSelection" v-model="link_relationship" row hide-details>
												<v-radio :color="checkboxColor" :label="n.name" :value="n.id"
												 class="radioClass" :disabled="isReadOnly"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
									<v-row class="EDC-Row" style="padding-bottom:16px !important;">
										<v-col class="EDC-Col" cols="4" align="left">
											<b>{{join_from_table}}</b>
										</v-col>

										<v-col class="EDC-Col" cols="3">
											<v-autocomplete :items="jtype_list" item-text="jtype_list" v-model="jtype" dense hide-details :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col JoinRightSide" cols="4" align="left">
											<b>{{join_to_table}}</b>
										</v-col>
										<v-col class="EDC-Col" cols="1"></v-col>
									</v-row>

									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinLeftSide" :items="jfrom_table_list" v-if="jfrom_table_type==='BO'" item-text="table_description" item-value="table_name" return-object dense label="Select Table For Join"
										 	v-model="jfrom_selected_table" refs="jfrom_table_list" required clearable hide-details @input="new_jfrom_column_list=[];getColumnsForBoTable(jfrom_selected_table,jfrom_table_type,'jfrom')"  :disabled="isReadOnly || disabledTableForBOJoin()"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="3">

										</v-col>
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinRightSide" :items="jto_table_list" v-if="jto_table_type==='BO'" item-text="table_description" item-value="table_name" return-object dense label="Select Table For Join"
										 v-model="jto_selected_table" refs="jto_table_list" required clearable hide-details   @input="new_jto_column_list=[];getColumnsForBoTable(jto_selected_table,jto_table_type,'jto')" :disabled="isReadOnly || disabledTableForBOJoin()"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="1"></v-col>
									</v-row>

									<v-row class="EDC-Row">
										<v-col class="EDC-Col"  cols="4">
											<v-autocomplete class="JoinLeftSide" :items="new_jfrom_column_list" item-text="description" item-value="column_name" label="Select From"
										 	rule="selectFromrule" v-model="jfrom" refs="el_jfrom" required clearable  @input="JoinAcceleration" :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="3">
											<v-autocomplete :items="operators" item-text="name" item-value="id" v-model="join_opeator" label="Opeator" :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinRightSide" :items="new_jto_column_list" ref="el_jto" item-text="description" item-value="column_name" label="Select To"
										 	:rule="selectTorule" v-model="jto" required clearable :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="1" style="padding-top:20px !important;">
											<svgicon v-if="add_join_button" class="svg-icon-mini svg-fill-grid" name="add_v2"  :original="true" @click="add_join(add_join_button)" title="Add join" ></svgicon>
											<svgicon v-else class="svg-icon-mini svg-fill-mini" name="add_v2"  :original="true" @click="add_join(add_join_button)" title="Add join"></svgicon>
										</v-col>
									</v-row>
									<v-row class="EDC-Row" v-for="(item, index) in join_json_for_link" :key="item.id+index" style="padding-bottom:8px !important;">
										<v-col class="EDC-Col" cols="4" align="left">
											{{item.from_column_desc}}
										</v-col>
										<v-col class="EDC-Col" cols="3" align="left">{{changeText[item.operator]}}</v-col>
										<v-col class="EDC-Col" cols="4"  align="left">
											<div class="JoinRightSide">{{item.to_column_desc}}</div>
										</v-col>
										<v-col class="EDC-Col" cols="1"  style="padding-left:2px !important">
											<svgicon  class="svg-icon-mini svg-fill-mini" name="document_edit_v2"  :original="true" @click="update_join(item.from_column, item.operator, item.to_column, index)" title="Edit join"></svgicon>
											<svgicon class="svg-icon-mini svg-fill-mini" name="delete_v2"  :original="true" @click="delete_join(index)" title="Delete join" ></svgicon>

										</v-col>
									</v-row>

									<vc-button type="submit" :disabled="isReadOnly" itemText="Save"></vc-button>
								</v-container>
							</v-form>
					</v-card>
				</v-flex>
			</v-dialog>
			<v-dialog v-model="descriptionPanel" width="1200" eager>
			<v-card>
				<!-- <v-card-title> -->
					<rich-text-box style="backgroud:white" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj"></rich-text-box>
					<!-- </v-card-title> -->
					<!-- <v-divider></v-divider> -->
					<v-card-actions>
						<v-spacer></v-spacer>
						<vc-button  itemText= "Close" @click.native="descriptionPanel = false">

			            </vc-button>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-flex>
	</div>
</template>
<script>
	import {mixinEdcGraph} from '../../mixins/edcGraph.js'
	import Simplert from 'vue2-simplert'
	import tablelistpanel from "./../../views/tablelistpaneltree.vue";
	import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
	import edcGraph from '@/views/edcGraph/edcGraph.vue'
	import config from "../../config.json";
	import {
		JOIN_OPERATOR_ARRAY_JSON,
		JOIN_TYPE_FOR_DATAMAP,
		AGGREGATE_FUNCTIONS,
		FLITER_JOIN_OPERATOR_ARRAY_JSON,
		SERVER_ERROR
	} from "../../methods/flowcharts/constants.js";

	import {
		getTablesByDVEnv,
		getTableColumns,
		getRevisedColumnsWithDescription,
		getColumnsWithDescription,
		getTablesAssociateWithBO,
		getTableDescriptions
	} from "../../methods/agent_api_call.js";

	import {
		fetch_all_erp_designer,
		fetch_erp_version_for_designer
	} from "./../../methods/erp_details.js";

	import {
		post as postToServer
	} from "./../../methods/serverCall.js";
	import {
		get as getToServer
	} from "./../../methods/serverCall.js";
	import {
		ADD_BUSINESS_GRAPH_DATA,
		GET_BUSINESS_GRAPH_DATA,
		ALL_PUBLISHED_BUSINESS_OBJ_LIST,
		VALIDATE_DATA_MAP,
		ADD_GRAPH_DATA,
		GETENVIRONMENTDETAILS,
		GET_PROCESS_GRAPH_DATA,GET_PRIMARY_KEYS
	} from "../../data/url_constants.js";
	import dateformat from "./dateformat.vue";
	import Criteria from '@/views/newcriteria.vue';
	import {
		VALUE_TO_TEXT,VALIDATION_IN_RPOCESS_MESSAGE
	} from "../../methods/flowcharts/constants.js";
	import RichTextBox from "@/views/richTextEditor/index.vue";
	import {
		CHECKBOX_COLOR,COLOR_CODE
	} from "@/data/macros.js";
	import {CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js'
	import * as edcGraphConstants from "../../methods/edcGraph/constants.js"
	import * as edcGraphCommon from "../../methods/edcGraph/commonFunctions.js"
	import * as edcGraphObj from "../../methods/edcGraph/graph.js"

	export default {
		name:'DatamapDesigner',
		mixins:[mixinEdcGraph],
		components:{
			tablelistpanel,
			'rich-text-box':RichTextBox,
			Simplert,
			showerrorlisttemplate,
			'new-edc-criteria':Criteria,
			'edc-graph':edcGraph
		},
		data(){
			return {
				paperDimentions:{
					'width':1600,
					'height':1600
				},
				newCell:{},
				graphData:{},
				graphFor:'data_map',
				clientId:this.$session.get("client_id"),
				productName:this.$route.params.product_name,
				env_id: this.$session.get("selected_env").id,
				env_name: this.$session.get("selected_env").name,
				main_filterUI:[],
				secondary_filterUI:{groupList:[]},
				minimapTop:'370px',
				graphHeight:'450px',
				datasource_timer:'',
				link_relationshiplist: [{
						id: "1:1",
						name: "1:1"
					},
					{
						id: "1:M",
						name: "1:M"
					},
					{
						id: "M:1",
						name: "M:1"
					}
				],
				traversal_list: [{
						id: "DEPTH",
						name: "Depth First"
					},
					{
						id: "BREATH",
						name: "Breath First"
					}
				],
				datasource_error:'',
				descriptionPanel:false,
      			colorCode:COLOR_CODE,
				checkboxColor: CHECKBOX_COLOR,
				changeText: VALUE_TO_TEXT,
				snackbar:false,
      			snackbartext:'',
      			colorValue:'success',
      			avlColumnList: [],
				filter_dialog: false,
				errorListCount: 0,
				validateProgress: false,
				auto_increment_id: 0,
				currentTableObject: {},
				tableListLoader: false,
				data_map_id: null,
				join_keys_list: [],
				join_json_for_link: [],
				add_join_button: false,
				datamapname: "Untitled Data Map",
				newly_created_link: "",
				link_relationship: "1:1",
				traversal_type: "DEPTH",
				table_list: [],
				jfrom_id:'',
				jto_id:'',
				connection_str: "",
				schema: "",
				db_type: "",
				default_table_list: [],
				join_from_table: "",
				join_to_table: "",
				jto_table_type:"",
				jfrom_table_type:"",
				join_from_table_id: "",
				jto_edc_id:"",
				jfrom_edc_id:"",
				jto_datasource_index:0,
				jfrom_datasource_index:0,
				join_to_table_id: "",
				jtype_list: JOIN_TYPE_FOR_DATAMAP,
				jtype: "Inner Join",
				stype: "Self Join",
				jto_column_list: [],
				new_jto_column_list:[],
				jto_column_description:[],
				jfrom_column_list: [],
				new_jfrom_column_list:[],
				jfrom_column_description:[],
				join_json: [],
				operators: JOIN_OPERATOR_ARRAY_JSON,
				filter_operators: FLITER_JOIN_OPERATOR_ARRAY_JSON,
				jto: "",
				jfrom: "",
				traverse_by:"",
				join_opeator: "EQUALS_TO",
				dbl_click_tbl_items: [],
				selectFromrule: [v => !!v || "Select from required"],
				selectTorule: [v => !!v || "Select to is required"],
				filter_rows_list: [],
				tbl_dialog: false,
				unique_identifier_dialog: false,
				dbl_click_tbl_cell_name: {},
				columns_for_unique_identifiers: [],
				uniqueIdentifierCell: {},
				uniqueIdentifierCellWrapper:{},
				filter_column_tbl_obj: {
					s_parentheses: "",
					column_name: "",
					operators: "",
					v_type1: 'Value',
        			v_type2: 'Value',
					value1: "",
					value2: "",
					e_parentheses: ""
				},
				timer: "",
				savedstatus: "edited",
				filter_json: {},
				link_filter_json:{},
				cell_id: "",
				dragged_tbl_list: [],
				business_obj_list:[],
				filtertablename: "",
				dateformat_dialog: false,
				tablelistpanelproperties: {},
				has_flattening_restriction: true,
				filter_popup_index: 0,
				datasource_timer_sec: 10000,
				is_error_occured: false,
				is_request_in_process:false,
				dragged_tbl_list_with_its_type:[],
				bo_description:'',
	            vueEditorObj:{},
	            showObjectDetails:false,
	            reqInputData:{},
	            oldDMName:"Untitled Data Map",
	            validation_response:{},
	            bographdata:{},
	            acceleratorObj:{},
	            columnDatatableList:{
		        headers: [
		        { text: 'Unique Keys', value:'forunique', width:'8%', useCheckbox:true},
		        { text: 'Column Name', value: 'column_name',width : "30%"},
		        { text: 'Description', value: 'description',width : "62%"},
		        ],
		        actions: [],
		        sorting_type: CLIENT_SIDE,
		        filterType: CLIENT_SIDE,
		        paginationType: CLIENT_SIDE,
		        total_count: 0,
		        rows: [],
		        perPage:10,
		        select_rows:false,
		        hidePagination:false,
		        hidePerPageArray:true,
		        hideRowInformation:true,
		        hideExport:true,
		        multipleGrid:true,
		        hideShowColumns:true,
		        hideSelect:true,
		        envIpJson : {
		              "filter": [],
		              "sort": {
		                "column": "",
		                "type": ""
		              },
		              "page": 1,
		              "page_size": "*"
		            },
			    },
				isReadOnly:false,
				erpDetails:false,
				erp_code:this.$route.params.erpCode,
			    erp_version:this.$route.params.erpVersion,
			    cur_erp_code:"",
				cur_erp_version:"",
				erp_list:[],
			    erp_version_list:[],
			    erp_nameRules: [v => !!v || 'ERP Name is required.'],
				erp_versionRule: [v => !!v || 'ERP Version is required.'],
				traverse_by_rule: [v => !!v || 'Traverse By is required.'],
				filter_for:'',
				selected_env:{},
				link_dialog:false,
				businessObjectShapeActions:{},
				propChangedFor:{},
				cellToRemove:{},
				dataForGraphRedesign:{},
				validationGraphObj:{},
				jto_table_list:[],
				jfrom_table_list:[],
				tableDescriptionList:[],
				jfrom_selected_table:{},
				jto_selected_table:{},
				autoArrangeGraph:false,
				valid:'',
				isObjectFullyLoaded:false, // call getting send to backend before object get loaded fully.
				tableListByBusinessObject:{}, // this json variable is used to store tablelist by business object id. It will help to minimize db calls
				allAvailableDS:[],
				undoStack:[] ,
				redoStack:[],
				redoundoMsg:'',
				copyJoinJson:[],
				redoundoTimer:null,
				nameTextboxSize:20,
				showErrorPopUp:false,
				isTemplate:false,
			}
		},
		computed:{
			table_column_list() {
				let data = this.$store.state.cacheData[
				this.env_id + "_table_column_list"];
				return data ? data : [];
			}
		},
		watch: {
			join_json(newvalue) {
				this.ChangeSaveStatus();
			},
			dragged_tbl_list(newvalue) {
				this.ChangeSaveStatus();
			},
			filter_rows_list(newvalue) {
				this.ChangeSaveStatus();
			},
			graphData(newvalue){
				// alert('save')
			},
			datamapname(newvalue,oldvalue){
				if(newvalue){
					this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
				}
			},
		},
		created() {
			this.savedstatus = "saved";
		},
		mounted(){
			var _this = this
			document.addEventListener('keyup',  _this.keyupHandler)
			if(!this.$route.params.data_map_id){
				_this.startInitialSaveTimer(5000)
			}

			$('#datamapdesigner').on('keydown',function(event){
		      if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
		          event.preventDefault()
		    });

			let env_id = this.$session.get('last_selected_env_id')
			// _this.getEnvDetails(env_id)
			var client_id = _this.$session.get("client_id")
			fetch_all_erp_designer(_this,client_id)
			var paper_container = document.getElementById("main_container");
			paper_container.addEventListener("drop", drop_on_paper_container);
			paper_container.addEventListener("dragover", allowDrop_on_paper_container);
			_this.graphHeight = (window.innerHeight-110)+'px';
			_this.minimapTop = (window.innerHeight-280)+'px';//60 internal minimap height + 140 top navigation height
			function drop_on_paper_container(event) {
				if(_this.isReadOnly)
					return false
				event.preventDefault();
				var table_name = _this.currentTableObject.title; //event.dataTransfer.getData("table_name");
				var table_type = _this.currentTableObject.type;
				var object_id = _this.currentTableObject.object_id;
				var table_version = _this.currentTableObject.object_version;
				var table_description = _this.currentTableObject.table_description
				var datasource_id = _this.currentTableObject.datasource_id
				var datasource_index = _this.currentTableObject.datasource_index
				_this.newCell = {
					"edc_type":'bo_table',
					"table_name":table_name,
					"table_type":table_type,
					"object_id":object_id,
					"object_version":table_version,
					"positionX":event.offsetX,
					"positionY":event.offsetY,
					"table_description":table_description,
					"datasource_id":datasource_id,
					"datasource_index":datasource_index,
					"applicable_for_REDOUNDO":true
				}
			}

			function allowDrop_on_paper_container(event) {
				event.preventDefault();
			}

			_this.env_id = env_id

		if(_this.$route.params.isReadOnly==='N')
			_this.isReadOnly = false

		var env_has_allow_create = true
		if(!env_has_allow_create){
			_this.isReadOnly = true
			_this.RedesignGraph('No');
		}
		else
		{
			if (_this.$route.params.data_map_id) {
				if (this.$route.params.copy) {
					_this.isReadOnly = false
					_this.CopyGraph();
				}
				else {
					_this.RedesignGraph();
					if(this.$route.params.is_read_only){
						this.isReadOnly = true

					}
					else{
					// here add code for open in read only and allow edit
					var data = {
						object_id: _this.$route.params.data_map_id,
						object_revision: _this.$route.params.object_revision,
						object_type_code: _this.$route.params.object_type_code,
						env_id: _this.$route.params.env_id,
						env_name: _this.$route.params.env_name
					};
					_this.data_map_id = _this.$route.params.data_map_id
					_this.object_revision =_this.$route.params.object_revision
					if(!_this.$route.params.create_new_version)
						_this.checkobjectversion(data)
					else
						_this.isReadOnly = false
					}
				}
			} else {
				_this.isReadOnly = false
				_this.Get_Tables();
			}
		}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			clearInterval(this.datasource_timer);
		},
		destroyed(){
	  		document.removeEventListener('keyup', this.keyupHandler)
		},
		methods:{
			async getEnvDetails(env_id){
				var _this = this
				debugger;
				var environment_end_point = config.ENVIRONMENT_API_URL + GETENVIRONMENTDETAILS + env_id
	    		await getToServer(_this, environment_end_point, true).then(envResponse => {
					   _this.selected_env = envResponse.environment_details
					   _this.$session.set('selected_env', _this.selected_env)

	        	}).catch(error=>{
	        	alert('load env failed')
	        	})
			},
			getColumnsForBoTable(table_data,table_type,is_from){
				let _this = this
				console.log(table_data)
				var kwargs = _this.getDatasourceObj(table_data.datasource_index)
				_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_data.table_name,table_data.table_type))
				getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
				if(is_from === "jfrom"){
					_this.jfrom_column_list = tableColumnResponse.column_db_details;
					// this.jfrom_column_list.push(tableColumnResponse.column_list)
					_this.jfrom_column_description = tableColumnResponse.description
					_this.new_jfrom_column_list = []
					_this.createJoinColsDesc(_this.jfrom_column_list, _this.new_jfrom_column_list, _this.jfrom_column_description, false, kwargs.table_name)
					// this.new_jfrom_column_list.push(this.new_jfrom_column_list_1)
					// _this.new_jfrom_column_list = _this.new_jfrom_column_list.concat(_this.new_jfrom_column_list_1)
				}
				else{
					_this.jto_column_list = tableColumnResponse.column_db_details;
					_this.jto_column_description = tableColumnResponse.description
					_this.new_jto_column_list = []
					_this.createJoinColsDesc(_this.jto_column_list, _this.new_jto_column_list, _this.jto_column_description, false, kwargs.table_name)
				}
			})
			.catch();
			},
			receiveLinkEvent(eventName,data){
				let _this = this
				let link = data.link
				if(eventName === 'addbusinesslink'){
					_this.designAutoRelationship(link.id,data.target)
				}
				else if(eventName === 'connect'){
					_this.link_dialog = true
					_this.getLinkDetails(link,data.source,data.target)
				}
				else if(eventName === 'doubleclick')
				{
					_this.link_dialog = true
					_this.getLinkDetails(link,data.source,data.target,data.linkData)
				}
				else if(eventName === 'linkfilter'){
					_this.getLinkDetails(link,data.source,data.target)
					_this.showLinkFilterRow(link)
				}
				else if(eventName === 'remove'){
					_this.perform_link_Removed(link.id,data)
				}
			},
			receiveElementEvent(eventName,data){
				let _this = this
				let element = data.element
				if(eventName === 'add' && element.prop('edc_cell_type') === edcGraphConstants.BUSINESS_EDC_CELL_TYPE){
					// _this.getDatasourceDetails(element.prop('datasource_id'))
					_this.perfromNewTableAdd(element)
					if(element.prop('applicable_for_REDOUNDO')){
						let zippedElement = edcGraphObj.zipElement(element,_this.graphFor)
						zippedElement["edc_type"] = 'bo_table'
						this.pushForUndo('add_element',{"element":zippedElement})
					}

				}

				else if(eventName === 'remove'){
					_this.performElementRemove(data.element)
				}
				else if(eventName === 'click'){
					_this.showFilterRow(element);
				}else if(eventName === 'showfilter'){
					_this.showFilterRow(element,true)
				}
				else if(eventName === 'showcols'){

				}else if(eventName === 'clearidentifiers'){
					_this.clearAllIdentifier()
				}
			},
			receiveCellEvent(data){
				let _this = this

			},
			receiveGraphEvent(eventName,data){
				let _this = this
				_this.graphData = data.graphData
				if(edcGraphConstants.GRAPH_EVENTS_FOR_REDESIGN.indexOf(eventName)>-1){
					_this.ChangeSaveStatus()
				}

			},
			receivePaperEvent(data){
				let _this = this

			},
			perfromNewTableAdd(element){
				let _this = this
				var table_name = element.prop("table_name");
				var table_type = element.prop("table_type")
				var object_id = element.prop("object_id")
				var object_version = element.prop("object_version")
				var datasource_id = element.prop('datasource_id')
				var datasource_index = element.prop('datasource_index')
				if(!datasource_index)
					datasource_index = 0

				_this.dragged_tbl_list.push(table_name);
				if(table_type === "BO")
					_this.dragged_tbl_list_with_its_type.push({"table_name":table_name,"table_type":table_type,"object_id":object_id,"object_version":object_version,
					'datasource_index':datasource_index})
				else{
					_this.dragged_tbl_list_with_its_type.push({"table_name":table_name,"table_type":table_type,'datasource_index':datasource_index,'datasource_id':datasource_id})

					var kwargs = _this.getDatasourceObj(datasource_index)
					_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,table_type))
					getRevisedColumnsWithDescription(_this,kwargs);
				}
			},
			fetch_erp_version(){
				var _this= this
				var client_id = _this.$session.get("client_id")
				fetch_erp_version_for_designer(_this, client_id, _this.cur_erp_code)
			},
			checkobjectversion(record){
	            let _this = this;
	            if(record.object_version){
	                var object_revision = record.object_version
	            }else{
	                var object_revision =  record.object_revision
	            }
	            var inputJson =  {"object_id": record.object_id, 
				"object_type_code": record.object_type, 
				"object_revision": object_revision, 
				"env_id": this.$session.get('selected_env').id}
	            postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'CREATE_NEW_VERSION'){
                        	this.isReadOnly = true
                            this.ShowConfirm(record)
                        } else if (response.status == 'PENDING_APPROVAL'){
                        	this.isReadOnly = true
                            alert("Object is in Approval pending state, Can not edit object")
                        } else {
                            _this.isReadOnly = false
                        }
                    } else {
                       this.errorMsg = response
                    }
	            }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0;
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
	        },
        ShowConfirm(object){
        let confirmFn = function () {
        this.$parent.SendObjectForNewVerion(object)
        }
        let obj = {
          title: 'Published Object',
          message: 'This Object has been published. You can create a new version or open it in read only mode',
          type: 'info',
          useConfirmBtn: true,
          onConfirm: confirmFn,
          customCloseBtnText:'Read Only',
          customConfirmBtnText:'New Version'
        }
        this.$refs.simplert.openSimplert(obj)
      },

		closeFilterPanel(){
			alert("Hello");
		},
		deleteElement(type) {
			let _this = this;
			ContextMenuItemClick(this, "delete");
		},
		async openFilterPanel(columnList) {
			let _this = this;
			this.avlColumnList = [];
			let tableColumns = _.cloneDeep(columnList)
			// from cache service, we got response as columnList.column_list
			if(tableColumns && tableColumns.column_list)
				tableColumns = tableColumns.column_list
			if (tableColumns){
				this.avlColumnList = tableColumns;
				}
			else {
				await this.jfrom_column_list.map(str => {
					let obj = _this.join_from_table + "." + str;
					_this.avlColumnList.push(_.cloneDeep(obj));
				});
				await this.jto_column_list.map(str => {
					let obj = _this.join_to_table + "." + str;
					_this.avlColumnList.push(_.cloneDeep(obj));
				});
			}
			this.filter_dialog = true;
		},
		openJoinPanel() {
			let _this = this;
			_this.link_dialog = true;
		},
		MouseOverEvent(obj, condition) {
			obj.target.style.backgroundColor = "grey";
		},
		MouseOutEvent(obj) {
			obj.target.style.backgroundColor = "white";
		},
		draggedObject(object) {
			this.currentTableObject = object;
		},
		hideDetails() {
			let _this = this;
			setTimeout(function () {
				_this.tableListLoader = false;
			}, 500);
		},
		Get_Tables() {
			var _this = this;
			var _this = this;
			if (_this.datasource_timer_sec <= 10000) _this.tableListLoader = true;
			_this.datasource_error = "Connecting to business datasource";
			clearInterval(_this.datasource_timer);
			 // dictionary parameters
			var search_types = ["table","view","alias"]
      		var kwargs = {"description_needed":true,
                    "check_in_description":false,
                    "object_type":search_types}

			getTablesByDVEnv(
				_this,{'env_id':_this.env_id,"description_needed":true,
                "check_in_description":false,
				"client_id": this.$session.get('client_id')}
			).then(tableResponse => {
				_this.datasource_error = "";
				_this.tableListLoader = false;
				let ds_id_list = []
				_.forEach(tableResponse.available_datasource_list,(obj,indx)=>{
						ds_id_list.push(obj.datasource_id)
					})
				_this.allAvailableDS = tableResponse.available_datasource_list
				if(ds_id_list != [])
					_this.getBusinessObjectList(ds_id_list, tableResponse)
				else
					_this.tablelistpanelproperties = tableResponse
				_this.acceleratorObj['acceleration_type'] = tableResponse.available_datasource_list[0].acceleration_type
			})
			.catch(errorResponse => {
				_this.tableListLoader = false;
				_this.datasource_error =
					"Enabled to connect business datasource.Retry in " +
					_this.datasource_timer_sec / 1000 +
					" seconds";
				_this.datasource_timer = setInterval(
					_this.Get_Tables,
					_this.datasource_timer_sec
				);
				_this.datasource_timer_sec = _this.datasource_timer_sec * 2;
			});
		},
		saveCriteria(columns_for_filter,main_filter,secondary_filter){
      		// debugger;
      		this.main_filterUI = main_filter
      		this.secondary_filterUI=secondary_filter
      		this.filter_rows_list = _.cloneDeep(columns_for_filter);
		},
		closeFilter(action, isFromRedoUndo=false){
		// this method is created for the show group icon properly
		if(action==='save'){
			if(this.filter_for === 'cell'){
		      	if(!this.filter_json[this.cell_id])
		      		this.filter_json[this.cell_id] = {}
		      		this.filter_json[this.cell_id]["filter_rows_list"] = _.cloneDeep(this.filter_rows_list)
		      		if(this.filter_rows_list.length>0 && this.filter_rows_list[0].column_name)
			      		this.propChangedFor = {
			      			"cell_id":this.cell_id,
			      			"action":"add",
							"changed_for":'filter'
			      		}
			      	else{
			      		this.propChangedFor = {
			      			"cell_id":this.cell_id,
			      			"action":"remove",
							"changed_for":'filter'
			      		}
			      	}
	      	}
		    else{
		      	if(!this.link_filter_json[this.cell_id])
		      		this.link_filter_json[this.cell_id] = {}
		      		this.link_filter_json[this.cell_id]["filter_rows_list"] = _.cloneDeep(this.filter_rows_list)
		      }
			// send event for filter change
			    if(!isFromRedoUndo){
			    	this.pushForUndo("filter_change",{"old_value":_.cloneDeep(this.filter_rows_list),"new_value":oldFilterForUndo,"filter_for":this.filter_for,"cell_id":this.cell_id,"link":this.zippedLink})
			    }

		}
		this.filter_dialog = false
		if(!isFromRedoUndo){
				this.main_filterUI = []
				this.secondary_filterUI = {groupList:[]}
			}
		this.ChangeSaveStatus();
		},
		OnNameChange() {
			// code check for if user didnt specify any name to object then we need to give old name
			if(!this.datamapname)
				this.datamapname = this.oldDMName
			if(this.datamapname != this.oldDMName){
				this.pushForUndo('dm_name_changed',{"old_value":this.oldDMName,"new_value":this.datamapname})
			}
			this.ChangeSaveStatus();
			if (!this.timer) this.timer = setInterval(this.AutoSaveDatamapGraph, 3000);
		},
		ChangeSaveStatus() {
			//
			this.is_error_occured = false;
			this.savedstatus = "edited";
			if (!this.timer) this.timer = setInterval(this.AutoSaveDatamapGraph, 3000);
		},
		getdate(index, data) {
			index = this.filter_popup_index;
			var date_data = _.cloneDeep(data);
			this.filter_rows_list[index].value = date_data["value"];
			this.filter_rows_list[index].datedetails = date_data;
			this.dateformat_dialog = false;
			this.ChangeSaveStatus();
		},
		perform_link_Removed(link_id,data) {
			this.copyJoinJson = _.cloneDeep(this.join_json)
			let relatedJoins = []
			for (var i = 0; i < this.join_json.length; i++) {
				if (Object.keys(this.join_json[i])[0] === link_id) {
					relatedJoins = this.join_json.splice(i, 1);
					break;
				}
			}
			let relatedFilters = {}
			if(this.link_filter_json[link_id]){
				relatedFilters = _.cloneDeep(this.link_filter_json[link_id])
				delete this.link_filter_json[link_id]
			}
			if(data && data.stackObj){
				data.stackObj['relatedJoins'] = relatedJoins
				data.stackObj['relatedFilters'] = relatedFilters
				this.pushForUndo(data.stackObj.user_action,data.stackObj)
			}
			this.ChangeSaveStatus();
		},
		performElementRemove(element) {
			if(!element.prop('is_embed'))
				return
			var table_to_removed = element.prop("table_name")
			var element_id = element.id;
			this.filter_rows_list = [];
			this.main_filterUI = []
			this.secondary_filterUI = {groupList:[]}
			if (this.dragged_tbl_list.includes(table_to_removed)) {
				this.dragged_tbl_list.splice(
					this.dragged_tbl_list.indexOf(table_to_removed),
					1
				);
				for (var i = 0; i< this.dragged_tbl_list_with_its_type.length; i++) {
					if(this.dragged_tbl_list_with_its_type[i]['table_name'] === table_to_removed){
						this.dragged_tbl_list_with_its_type.splice(
							this.dragged_tbl_list_with_its_type.indexOf(this.dragged_tbl_list_with_its_type[i]),1);
						break;
					}
				}
			}
			delete this.filter_json[element_id];

			this.ChangeSaveStatus();
		},
		ZoomAction(action) {
			performZoomActions(this, action);
		},
		ContextMenuClick(action_type) {
			ContextMenuItemClick(this, action_type);
		},
		getLinkDetails(link,source,target,linkData) {
			var _this = this
			_this.newly_created_link = link
			this.zippedLink = edcGraphObj.zipLink(link,_this.graphFor)
			this.link_id = link.id;
			this.jfrom = "";
			this.jto = "";
			this.join_json_for_link = "";
			this.join_keys_list = [];
			this.join_opeator = "_eq_";
			// this.env_id = this.$session.get("selected_env").id;
			var source_id = source.id;
			var target_id = target.id;
			this.join_from_table = source.prop("table_name")
			this.jfrom_id = source.prop("object_id")
			this.jfrom_table_type = source.prop("table_type");
			this.jfrom_edc_id = source.prop("table_name")
			var jfrom_datasource_id = source.prop('datasource_id')
			this.jfrom_datasource_index = source.prop('datasource_index')
			this.jfrom_selected_table = {}
			this.jto_selected_table = {}
			if(source.prop("table_type") === "BO"){
				this.jfrom_edc_id = source.prop("object_id")
				if(_this.tableListByBusinessObject[_this.jfrom_edc_id])
					_this.jfrom_table_list = _this.tableListByBusinessObject[_this.jfrom_edc_id]
				else{
					let kwargs = {"table_column_list":_this.jfrom_table_list, "env_id":_this.env_id, "table_name":_this.join_from_table, "jfrom_id":_this.jfrom_edc_id,
					'datasource_index':this.jfrom_datasource_index,'allAvailableDS':_this.allAvailableDS}
						getTablesAssociateWithBO(_this,kwargs).then(tableListinBOResponse => {
							_this.jfrom_table_list = tableListinBOResponse
							_this.tableListByBusinessObject[_this.jfrom_edc_id] = tableListinBOResponse
							_this.bindTableForBOJoin(_this.jfrom_table_list,_this.new_jfrom_column_list,_this.jfrom_table_type,'jfrom')
					}).catch();
				}
			}
			this.join_to_table = target.prop("table_name")
			this.jto_id = target.prop("object_id")
			this.jto_table_type = target.prop("table_type");
			this.jto_edc_id = target.prop("table_name")
			var jto_datasource_id = target.prop('datasource_id')
			this.jto_datasource_index = target.prop('datasource_index')
			if(target.prop("table_type") === "BO"){
				this.jto_edc_id = target.prop("object_id")
				if(_this.tableListByBusinessObject[_this.jto_edc_id])
					_this.jto_table_list = _this.tableListByBusinessObject[_this.jto_edc_id]
				else{
					let kwargs = {"table_column_list":_this.jto_table_list, "env_id":_this.env_id, "table_name":_this.join_to_table, "jfrom_id":_this.jto_edc_id,
					 'datasource_index':this.jto_datasource_index,'allAvailableDS':_this.allAvailableDS}
						getTablesAssociateWithBO(_this,kwargs).then(tableListinBOResponse => {
							_this.jto_table_list = tableListinBOResponse
							_this.tableListByBusinessObject[_this.jto_edc_id] = tableListinBOResponse
							_this.bindTableForBOJoin(_this.jto_table_list,_this.new_jto_column_list,_this.jto_table_type,'jto')
					}).catch();
					}
			}
			if (link.prop("labels/0/attrs/text/text"))
				this.link_relationship =
				link.prop("labels/0/attrs/text/text") +
				":" +
				link.prop("labels/1/attrs/text/text");
			// .model.label(0, { attrs: { text: { text: 'new label' } } });
			if (!this.link_relationship)
				this.link_relationship = "1:1";

			// has_flattening_restriction default should be true.Issue no.587
			if(link.prop("has_flattening_restriction") === false)
				this.has_flattening_restriction = false
			else
				this.has_flattening_restriction = true


			this.join_from_table_id = source_id;
			this.join_to_table_id = target_id;

			if (this.jfrom_table_type != "BO"){
				let table_name = _this.join_from_table
					var kwargs = _this.getDatasourceObj(this.jfrom_datasource_index)
					_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,this.jfrom_table_type))
					getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
						this.jfrom_column_list = tableColumnResponse.column_db_details;
						var description = tableColumnResponse.description
						this.jfrom_column_description = description
						this.new_jfrom_column_list = []
						_this.createJoinColsDesc(this.jfrom_column_list, this.new_jfrom_column_list, description, false , table_name)
					})
					.catch();
			}

			if (this.jto_table_type != "BO"){
				let table_name = _this.join_to_table
					var kwargs = _this.getDatasourceObj(this.jto_datasource_index)
					_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,this.jto_table_type))
					getRevisedColumnsWithDescription(_this,kwargs)
					.then(tableColumnResponse => {
						this.jto_column_list = tableColumnResponse.column_db_details;
						var description = tableColumnResponse.description
						this.new_jto_column_list = []
						this.jto_column_description = description
						_this.createJoinColsDesc(this.jto_column_list, this.new_jto_column_list, description, false, table_name)
					})
					.catch();
			}
			let linkJoinIndex = -1
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					linkJoinIndex = i
					this.join_keys_list = this.join_json[i][this.link_id].condition;
					this.join_json_for_link = this.join_json[i][this.link_id].condition;
					this.jtype = this.join_json[i][this.link_id].type;

					// now assign jfrom_selected_table if jfrom is business object and its present in the this.join_json[i][this.link_id]
					if(this.jfrom_table_type === 'BO'){
						_this.bindTableForBOJoin(_this.jfrom_table_list,_this.new_jfrom_column_list,_this.jfrom_table_type,'jfrom')
					}
					if(this.jto_table_type === "BO")
					{
						_this.bindTableForBOJoin(_this.jto_table_list,_this.new_jto_column_list,_this.jto_table_type,'jto')
					}
					break;
				}
			}
			if(linkData && linkData.isForJoin){
				_this.manageJoinsRedoUndoActions(linkData,linkJoinIndex)
			// 	setTimeout(function () {
			// 	_this.manageJoinsRedoUndoActions(linkData,linkJoinIndex)
			// }, 500);

			}
		},
		bindTableForBOJoin(table_list,column_list,join_obj_type,type){
			let _this = this
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					let selected_table = _.find(table_list,["table_name",this.join_json[i][this.link_id][type]])
					if(selected_table){
						if(type === 'jfrom')
							_this.jfrom_selected_table = selected_table
						else
							_this.jto_selected_table = selected_table
						column_list=[]
						_this.getColumnsForBoTable(selected_table,join_obj_type,type)
					}
					break;
				}
			}

		},
		disabledTableForBOJoin(){
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					if (this.join_json[i][this.link_id].condition && this.join_json[i][this.link_id].condition.length)
						return true
				}
			}
			return false
		},
		createJoinColsDesc(source_list,target_list, description,is_unique_identifier, table_name){
			var substringIndex = 0
			// var table_name = this.join_from_table
        	if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
          		substringIndex = 2
			_.forEach(source_list,function(column){
				let description_text = ""
				let each_item = column['name']
				column["column_name"] = each_item
				column['is_row_editable'] = true
				if(description && description.length > 0)
				{
					if(is_unique_identifier)
						substringIndex = 0
					description_text = edcGraphCommon.getColDescription(substringIndex,each_item,description,false)
					if (description_text)
						description_text = " ("+description_text+")"
					else
						description_text = ''
				}
				if(table_name){
					column["table_name"] = table_name
					// "description":table_name + "." + each_item+""+description_text ,
					column["description"] = table_name + "." +each_item+""+description_text
				}
				else{
					column["description"] = each_item+""+description_text
				}
			target_list.push(column)
			})
		},
		getDescriptionInCondition(column_name,join_side){
			var substringIndex = 0
	    	if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
	      		substringIndex = 2
	      	var description = this.jto_column_description
	      	if(join_side === 'from'){
	      		description = this.jfrom_column_description
	      		if(!description)
	      			description = []
	      		if(this.join_from_table === "BusinessObjectKeys" || this.join_from_table === "Business Object Keys"){
	      			substringIndex = 0
	      		}
			  }
			//   if(column_name.includes(".")){
			// 	column_name = column_name.split(".")[1]
			//   }
	      	var description_text = edcGraphCommon.getColDescription(substringIndex,column_name,description,false)
	      	if(description_text)
	      		return column_name +" ("+description_text+")"
	      	return column_name
		},
		getJoinJson(jfrom,jto){
			var _this = this
			// 	let jfrom_index = _.findIndex(_this.new_jfrom_column_list, function(o) { return o.column_name ===jfrom })
			// let jto_index =_.findIndex(_this.new_jto_column_list, function(o) { return o.column_name === jto })
			return {
				jfrom: jfrom,
				from_table_name :_this.join_from_table,
				jfrom_table_type:_this.jfrom_table_type,
				jto: jto,
				type: _this.jtype,
				condition: _this.join_keys_list,
				jfrom_id: _this.join_from_table_id,
				to_table_name:_this.join_to_table,
				jto_table_type:_this.jto_table_type,
				jto_id: _this.join_to_table_id,
				jfrom_edc_id: _this.jfrom_edc_id,
				jto_edc_id:_this.jto_edc_id,
				join_relationship: _this.link_relationship,
				has_flattening_restriction: _this.has_flattening_restriction, //on UI we are showing opposite of it. Need to remove aflter demo
				hierarchyName: _this.newly_created_link.prop('hierarchy_name'),
				relationshipName: _this.join_from_table + "-" + _this.join_to_table,
				jfrom_datasource_index:_this.jfrom_datasource_index,
				jto_datasource_index:_this.jto_datasource_index
			}

		},
		add_join() {
			if(this.isReadOnly)
				return false

			let _this = this

			var link_found = false;
			var link = this.newly_created_link

			/* update link details/flattening restriction details or create new join only if user specified the any join between two tables

				this.jfrom && this.jto this condition will get apply at first time

				this.join_keys_list.length > 0 this condition will get apply when user want to update the relationship mapping or flattening updates*/
			if ((this.jfrom && this.jto) || this.join_keys_list.length > 0) {
				link.label(0, {
					attrs: {
						text: {
							text: this.link_relationship.split(":")[0]
						}
					}
				});
				link.label(1, {
					attrs: {
						text: {
							text: this.link_relationship.split(":")[1]
						}
					}
				});
				link.prop(
					"has_flattening_restriction",
					this.has_flattening_restriction
				);

				for (var i = 0; i < this.join_json.length; i++) {
					if (this.join_json[i][this.link_id]) {
						this.join_json[i][
							this.link_id
						].has_flattening_restriction = this.has_flattening_restriction;
						this.join_json[i][
							this.link_id
						].join_relationship = this.link_relationship;
						this.join_json[i][
							this.link_id
						].type = this.jtype;
						link_found = true;
						this.ChangeSaveStatus()
						break;
					}
				}
			}

			let jfrom_index = _.findIndex(this.new_jfrom_column_list, function(o) { return o.column_name ===_this.jfrom })
			let jto_index =_.findIndex(this.new_jto_column_list, function(o) { return o.column_name === _this.jto })
			if(jfrom_index < 0 || jto_index < 0)
				return

			var data = {
				jfrom: this.new_jfrom_column_list[jfrom_index].table_name,
				from_table_name :this.join_from_table,
				jfrom_table_type:this.jfrom_table_type,
				jto: this.new_jto_column_list[jto_index].table_name,
				type: this.jtype,
				condition: this.join_keys_list,
				jfrom_id: this.join_from_table_id,
				to_table_name:this.join_to_table,
				jto_table_type:this.jto_table_type,
				jto_id: this.join_to_table_id,
				jfrom_edc_id: this.jfrom_edc_id,
				jto_edc_id:this.jto_edc_id,
				join_relationship: this.link_relationship,
				has_flattening_restriction: this.has_flattening_restriction, //on UI we are showing opposite of it. Need to remove aflter demo
				hierarchyName: this.newly_created_link.prop('hierarchy_name'),
				relationshipName: this.join_from_table + "-" + this.join_to_table,
				jfrom_datasource_index:this.jfrom_datasource_index,
				jto_datasource_index:this.jto_datasource_index
			};

			if (!link_found) {
				var generate_dict = {};
				generate_dict[this.link_id] = data;
				this.join_json.push(generate_dict);
			}

			if (this.jfrom && this.jto) {
				let conditionJson = {
								from_column: _this.new_jfrom_column_list[jfrom_index].column_name,
								from_table_name: _this.new_jfrom_column_list[jfrom_index].table_name,
								from_column_desc: _this.new_jfrom_column_list[jfrom_index].description,
								jfrom_datatype:_this.new_jfrom_column_list[jfrom_index].data_type,
								operator: _this.join_opeator,
								to_column: _this.new_jto_column_list[jto_index].column_name,
								to_table_name: _this.new_jto_column_list[jto_index].table_name,
								to_column_desc: _this.new_jto_column_list[jto_index].description,
								jfrom: _this.join_from_table,
								jto: _this.join_to_table,
								jto_datatype:_this.new_jto_column_list[jto_index].data_type,

							}
				if (this.update_join_button) {
					var start_index = this.update_join_button - 1;
					let oldCondtionJson = _.cloneDeep(this.join_keys_list[start_index])
					var number_of_elements_to_remove = 1;
					var l_id = this.link_id;
					var j_from = this.jfrom;
					var j_operator = this.join_opeator;
					var j_to = this.jto;
					var join_from_table = this.join_from_table;
					var join_to_table = this.join_to_table;
					var _obj = this.join_json.map(function (el) {
						if (el[l_id]) {
							el[l_id]["condition"].splice(
								start_index,
								number_of_elements_to_remove, conditionJson
							// 	{
							// 	from_column: _this.new_jfrom_column_list[jfrom_index].column_name,
							// 	from_table_name: _this.new_jfrom_column_list[jfrom_index].table_name,
							// 	from_column_desc: _this.new_jfrom_column_list[jfrom_index].description,
							// 	jfrom_datatype:_this.new_jfrom_column_list[jfrom_index].data_type,
							// 	operator: _this.join_opeator,
							// 	to_column: _this.new_jto_column_list[jto_index].column_name,
							// 	to_table_name: _this.new_jto_column_list[jto_index].table_name,
							// 	to_column_desc: _this.new_jto_column_list[jto_index].description,
							// 	jfrom: _this.join_from_table,
							// 	jto: _this.join_to_table,
							// 	jto_datatype:_this.new_jto_column_list[jto_index].data_type,

							// }
							);
						}
					});
					// old value and new value is litle confusing here but its corrent, because when user going to undo or redo we are swapping the values
					this.pushForUndo('update_join_condition',{
						"link_id":this.link_id,'old_value':conditionJson,'new_value':oldCondtionJson,'conditionIndex':start_index,
						'link':this.zippedLink,'isForJoin':true
					})
				} else {
					for (var i = 0; i < this.join_json.length; i++) {
						if (
							this.join_json[i][this.link_id] &&
							!this.join_json[i][this.link_id]["condition"].some(
								elem =>
								this.jfrom === elem.from_column &&
								this.jto === elem.to_column &&
								this.join_opeator === elem.operator
							)
						) {
							// let _this = this
							// let jfrom_index = _.findIndex(this.new_jfrom_column_list, function(o) { return o.description.contains(_this.jfrom); })
							// let jto_index =_.findIndex(this.new_jto_column_list, function(o) { return o.description.contains(_this.jto); })

							// this.join_json[i][this.link_id]["condition"].push({
							// 	from_column: this.new_jfrom_column_list[jfrom_index].column_name,
							// 	from_column_desc:this.new_jfrom_column_list[jfrom_index].description,
							// 	operator: this.join_opeator,
							// 	jfrom_datatype:this.new_jfrom_column_list[jfrom_index].data_type,
							// 	to_column: this.new_jto_column_list[jto_index].column_name,
							// 	to_column_desc: this.new_jto_column_list[jto_index].description,
							// 	jfrom: this.new_jfrom_column_list[jfrom_index].table_name,
							// 	jto: this.new_jto_column_list[jto_index].table_name,
							// 	jto_datatype:this.new_jto_column_list[jto_index].data_type,
							// });
							this.join_json[i][this.link_id]["condition"].push(conditionJson);
							this.pushForUndo('add_join_condition',{
								"link_id":this.link_id,'conditionJson':conditionJson,'conditionIndex':this.join_json[i][this.link_id]["condition"].length-1,
								'link':this.zippedLink,'isForJoin':true
							})
							this.join_json_for_link = this.join_json[i][
								this.link_id
							].condition;
							break;
						}
					}
				}
			}
			this.copyJoinJson = _.cloneDeep(this.join_json)
			this.jfrom = "";
			this.jto = "";
			this.join_opeator = "_eq_";
			this.update_join_button = false;
			this.ChangeSaveStatus();
		},
		update_join(j_from, j_cond, j_to, index) {
			if(this.isReadOnly)
				return false
			this.jfrom = j_from;
			this.jto = j_to;
			(this.join_opeator = j_cond), (this.update_join_button = index + 1);
		},
		delete_join(index) {
			if(this.isReadOnly)
				return false
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					let deleted_join = this.join_json[i][this.link_id]["condition"].splice(index, 1);
					this.pushForUndo('remove_join_condition',{
						"link_id":this.link_id,'conditionJson':deleted_join[0],'conditionIndex':index,
						'link':this.zippedLink,'isForJoin':true
					})
					break;
				}
			}
			this.ChangeSaveStatus();
		},
		delete_unique_identifier(index) {
			this.columns_for_unique_identifiers.splice(index, 1);
			this.ChangeSaveStatus();
		},
		dragitem(event) {
			event.dataTransfer.setData("table_name", event.target.innerText);
		},
		showFilterRow(cell,openFilter) {
			this.env_id = this.$session.get("selected_env").id;
			this.cell_id = cell.id;
			this.filter_for = 'cell'
			this.filter_column_list =[]
			var table_name = cell.prop('table_name')
			let table_id = cell.prop('object_id')
			this.filtertablename = table_name;
			var table_type = cell.prop("table_type");
			var _self = this;
			if (table_name !== "BusinessObjectKeys" && table_name !==  "Business Object Keys" && table_type === "BO"){
				var kwargs = {"env_id":_self.env_id,"table_name":table_name, "jfrom_id":table_id, "table_type": table_type}
				getTablesAssociateWithBO(_self,kwargs).then(tableListinBOResponse => {
				for (var i = 0; i < tableListinBOResponse.length; i++) {
							var kwargs = {"table_column_list":_self.table_column_list, "env_id":_self.env_id,"connection_str":_self.connection_str,"schema":_self.schema, "table_name":tableListinBOResponse[i], "table_type": "table"}
							let table_name = tableListinBOResponse[i]
							getTableColumns(
								this,
								this.table_column_list,
								this.env_id,
								this.connection_str,
								this.schema,
								table_name,
								"table"
							).then(tableColumnResponse => {
						_self.filter_column_list = _self.filter_column_list.concat(tableColumnResponse)
						if (!_self.filter_json[cell.id]) {
							var obj = _.cloneDeep(_self.filter_column_tbl_obj);
							obj["id"] = this.get_new_id();
							_self.filter_rows_list = [];
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list.push(obj);
							_self.filter_json[_self.cell_id] = {};
							_self.filter_json[_self.cell_id]["filter_rows_list"] =
								_self.filter_rows_list;
						} else {
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list =
								_self.filter_json[_self.cell_id]["filter_rows_list"];
						}
						if(openFilter)
							_self.openFilterPanel(tableColumnResponse)

					})
					.catch();
						}
				})
				.catch();
			}
			else if (table_name !== "BusinessObjectKeys" && table_name !==  "Business Object Keys" && table_type === "table") {
				getTableColumns(
						this,
						this.table_column_list,
						this.env_id,
						this.connection_str,
						this.schema,
						table_name,
						table_type
					)
					.then(tableColumnResponse => {
						_self.filter_column_list = tableColumnResponse;
						if (!_self.filter_json[cell.id]) {
							var obj = _.cloneDeep(_self.filter_column_tbl_obj);
							obj["id"] = this.get_new_id();
							_self.filter_rows_list = [];
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list.push(obj);
							_self.filter_json[_self.cell_id] = {};
							_self.filter_json[_self.cell_id]["filter_rows_list"] =
								_self.filter_rows_list;
						} else {
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list =
								_self.filter_json[_self.cell_id]["filter_rows_list"];
						}
						if(openFilter)
							_self.openFilterPanel(tableColumnResponse)

					})
					.catch();
			}
		},
		showLinkFilterRow(link) {
			let _self = this;
			this.cell_id = link.id;
			this.filter_for = 'link'
			/**
			 * If we dont have link id in link_filter_json
			 */
			 _self.main_filterUI = []
			_self.secondary_filterUI = {groupList:[]}
			if (!_self.link_filter_json[_self.cell_id]) {
				var obj = _.cloneDeep(_self.filter_column_tbl_obj);
				obj["id"] = this.get_new_id();
				_self.filter_rows_list = [];
				_self.filter_rows_list.push(obj);
				_self.link_filter_json[_self.cell_id] = {};
				_self.link_filter_json[_self.cell_id]["filter_rows_list"] =
					_self.filter_rows_list;
			} else {
				_self.filter_rows_list =
					_self.link_filter_json[_self.cell_id]["filter_rows_list"];
			}
			_self.openFilterPanel()
		},
		performcolsselection() {
			var add_fliter_row = false;
			var add_unique_identifier_row = false;
			this.columns_for_unique_identifiers = [];
			if (!this.filter_json[this.cell_id]) {
				this.filter_column_list = [];
			} else {
				this.filter_column_list = this.filter_json[
					this.cell_id
				].filter_column_list;
			}
			for (var i = 0; i < this.columnDatatableList.rows.length; i++) {
				if (this.columnDatatableList.rows[i].forunique === true) {
					var column_name = this.columnDatatableList.rows[i].column_name;
					var is_column_name_exists = this.columns_for_unique_identifiers
						.map(a => a)
						.includes(column_name);
					if (!is_column_name_exists) {
						this.columns_for_unique_identifiers.push(column_name);
						add_unique_identifier_row = true;
					}
				}
			}
			this.ChangeSaveStatus();
			this.tbl_dialog = false;
			this.unique_identifier_dialog = false;
		},
		clearAllIdentifier() {
			this.columns_for_unique_identifiers = [];
			this.dbl_click_tbl_items.map(item => {
				item.forunique = false;
			});
			this.columnDatatableList.rows = this.dbl_click_tbl_items
			this.hideDetails();
		},
		add_new_filter_row() {
			var current_filter_length = this.filter_rows_list.length;
			if (current_filter_length > 0) {
				if (!this.filter_rows_list[current_filter_length - 1]["operation"])
					this.filter_rows_list[current_filter_length - 1]["operation"] = false;
			}
			var obj = _.cloneDeep(this.filter_column_tbl_obj);
			obj["id"] = this.get_new_id();
			this.filter_rows_list.push(obj);
		},
		get_new_id() {
			return ++this.auto_increment_id;
		},
		delete_filter_row(idx) {
			var current_filter_length = this.filter_rows_list.length;
			if (current_filter_length > 1 && current_filter_length - 1 === idx)
				delete this.filter_rows_list[idx - 1]["operation"];
			this.filter_rows_list.splice(idx, 1);
			if (this.filter_rows_list.length === 0) {
				var obj = _.cloneDeep(this.filter_column_tbl_obj);
				this.filter_rows_list.push(obj);
			}
			this.ChangeSaveStatus();
		},
		GetData() {
			//
			this.env_id = this.$session.get("selected_env").id;
			var client_id = this.$session.get("client_id");
			var user = this.$session.get("email");
			let data = this.graphData
			var revised_filter_data = _.cloneDeep(this.filter_json);
			Object.keys(revised_filter_data).forEach(function (key) {
				if (
					revised_filter_data[key].filter_rows_list.length === 1 &&
					revised_filter_data[key].filter_rows_list[0].column_name === ""
				) {
					delete revised_filter_data[key];
				}
			});
			var revLinkFilterJson = _.cloneDeep(this.link_filter_json);
				Object.keys(revLinkFilterJson).forEach(function (key) {
				if (
					revLinkFilterJson[key].filter_rows_list.length === 1 &&
					revLinkFilterJson[key].filter_rows_list[0].column_name === ""
				) {
					delete revLinkFilterJson[key];
				}
			});
			//
			var business_data = {
				joins: this.join_json,
				filters: revised_filter_data,
				link_filters:revLinkFilterJson,
				// unique_identifier_id: this.uniqueIdentifierCell.id,
				// unique_identifier_wrapper_id : this.uniqueIdentifierCellWrapper.id
			};
			var graphData = {
				graph_data: data,
				data_map_name: this.datamapname,
				client_id: client_id,
				created_by: user,
				env_id: this.env_id,
				id: this.data_map_id,
				business_data: business_data,
				unique_identifier_keys: this.columns_for_unique_identifiers,
				table_list: this.dragged_tbl_list,
				auto_id: this.auto_increment_id,
				dragged_tbl_list_with_its_type:this.dragged_tbl_list_with_its_type,
				isAug2020ReleasedUI:true
			};
			return graphData;
		},
		Validatedatamapobj() {
			let _this = this;
			if(_this.validateProgress){
				alert(VALIDATION_IN_RPOCESS_MESSAGE)
				return false
			}
			_this.bographdata = {};
			_this.errorListCount = 0;
			var data = this.graphData
			var graphData = this.GetData();
			_this.validateProgress = true;
			_this.setErrorOnBO(false)
			// this.resetCriteriaValidation();
			var ValidateProcessDefinitionUrl =
				config.VALIDATION_URL + VALIDATE_DATA_MAP + "true";
			postToServer(this, ValidateProcessDefinitionUrl, graphData)
				.then(validation_response => {
					//
					_this.validation_response = validation_response
					_this.bographdata = data
					_this.validateProgress = false;
				})
				.catch(error_response => {
					_this.validateProgress = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
					} else {
						this.snackbar = true;
						this.snackbartext = "Something went wrong.Try Again";
						this.colorValue = "error";
					}
				});
		},
		setCriteriaError(filters) {
			let _this = this;
			let tableList = Object.keys(filters);
			//
			tableList.map(tableObj => {
				let rowIdArray = Object.keys(filters[tableObj]);
				rowIdArray.map(rowId => {
					let columnArray = Object.keys(filters[tableObj][rowId]);
					let errorObject = {
						columnName: "",
						message: ""
					};
					let rowIndex = rowId;
					/**
					 * @description for $set see https://vuejs.org/v2/guide/list.html#Caveats
					 */
					_this.$set(
						_this.filter_json[tableObj]["filter_rows_list"][rowIndex],
						"error_list", {}
					);
					columnArray.map(columnName => {
						let message = filters[tableObj][rowId][columnName];
						_this.filter_json[tableObj]["filter_rows_list"][
							rowIndex
						].error_list[columnName] = message;
						_this.errorListCount += 1;
					});
					// _this.filter_json[tableObj][rowIndex].error_list.name ="";
				});
			});
		},
		resetCriteriaValidation() {
			let _this = this;
			let tableList = Object.keys(_this.filter_json);
			_this.table_list_error = "";
			tableList &&
				tableList.map(table => {
					_this.filter_json[table] &&
					_this.filter_json[table]["filter_rows_list"] &&
					_this.filter_json[table]["filter_rows_list"].map(row => {
						_this.$set(row, "error_list", {});
					});
				});
		},
		toolbarSaveClicked(){
			let confirmFn = function () {
	        	this.$parent.AutoSaveDatamapGraph(true)
	        }
	        let obj = {
	          title: 'Save',
	          message: 'All changes will get saved, Proceed?',
	          type: 'info',
	          useConfirmBtn: true,
	          customConfirmBtnText:'Save',
	          customCloseBtnText:'Cancel',
	          onConfirm: confirmFn,
	          showXclose:true
	        }
	        this.$refs.simplert.openSimplert(obj)
		},
		showClosePopUp(){
			if(this.savedstatus === 'edited'){
				let confirmFn = function () {
		        	this.$parent.AutoSaveDatamapGraph(true,true)
		        }
		        let closeFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Some changes are not saved, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Save & Close',
		          customCloseBtnText:'Close Anyway',
		          onConfirm: confirmFn,
		          onClose:closeFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
			else{
				let confirmFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Tab will get close, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Close',
		          customCloseBtnText:'Cancel',
		          onConfirm: confirmFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
		},
		closeTab(){
			window.top.close()
		},
		AutoSaveDatamapGraph(isSaveClicked,closeTabAfterSave){
			var _this = this
			if(!this.isObjectFullyLoaded || this.isReadOnly)
				return

			// If autoSave is off, then save only when user click on save button
			if(!isSaveClicked && !this.isAutoSave)
				return
			this.env_id = this.$session.get("selected_env").id;
			this.env_name = this.$session.get("selected_env").name;
			if (this.savedstatus != "saved" && !this.is_request_in_process) {
				let data_send_backend = [];
				var graphData = this.GetData();
				if(!this.data_map_id && this.datamapname === "Untitled Data Map" && graphData && graphData.graph_data && (!graphData.graph_data.cells || !graphData.graph_data.cells.length)){
					/* This condition for IN CASE OF NEW OBJECT dont save unless user start creating the object. Means there has to be some different name of the business object than default one or atleast single table should be dragged */
					return
				}
				var client_id = this.$session.get("client_id");
				var user = this.$session.get("email");
				var graphData = {
					object_json: graphData,
					object_type_code: "DM",
					object_desc: "",
					erp_code : this.erp_code,
					erp_version : this.erp_version,
					object_name: this.datamapname,
					description: this.bo_description,
					object_revision: this.object_revision,
					client_id: client_id,
					added_by: user,
					env_id: this.env_id,
					env_name: this.env_name,
					object_id: this.data_map_id,
					template:this.isTemplate,
					product: this.productName
				};
				this.oldDMName = this.datamapname
				this.is_request_in_process=true
				postToServer(
						this,
						config.REPOSITORY_API_URL + ADD_GRAPH_DATA,
						graphData
					)
					.then(response => {
						this.is_request_in_process=false
						if (response) {
							var res = response;
							this.is_error_occured = false;
							this.data_map_id = response.object_id;
							this.datamapname = response.object_name;
							this.oldDMName = response.object_name;
							this.object_revision = response.object_revision;
							this.savedstatus = "saved";
						}
						if(closeTabAfterSave)
		      				_this.closeTab()
					})
					.catch(error_response => {
						_this.is_request_in_process = false
						if(error_response && error_response.is_select_erp == true){
	      					this.erpDetails = true
	      				}
						_this.is_error_occured = true;
						_this.savedstatus = "Error in the saving data into the databse.";
						if (error_response) {
							// this.snackbar = true
							// this.colorValue = 'error'
							// this.snackbartext = error_response;
							// this.is_error_occured=true
							// this.savedstatus='Error in the saving data into the databse.'
						} else {
							// this.snackbar = true
							// this.snackbartext = 'Something went wrong.Try Again';
							// this.colorValue = 'error'
							// this.is_error_occured=true
							// this.savedstatus='Error in the saving data into the databse.'
						}
					});
			}
		},
		RedesignGraph(env_has_allow_create,create_new_version) {
			if (this.$route.params.create_new_version || create_new_version) {
				var create_new_version = true;
			} else {
				var create_new_version = false;
			}
			// from object management page user click on create new version but env might not have create new version rights. So for that purpose added below check.
			if(env_has_allow_create === 'No')
				create_new_version = false
			if(create_new_version)
				this.isReadOnly = false
			this.env_id = this.$session.get("selected_env").id;
			var data = {
				object_id: this.$route.params.data_map_id,
				object_revision: this.$route.params.object_revision,
				object_type_code: this.$route.params.object_type_code ? this.$route.params.object_type_code : "DM",
				create_new_version: create_new_version,
				env_id: this.$route.params.env_id,
				ds_index: this.$route.params.ds_index,
				env_name: this.$route.params.env_name
			};
			var _this = this;

			_this.tableListLoader = true;
			postToServer(
					this,
					config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
					data
				)
				.then(response => {
					var res = response;
					//
					// this.env_id = res.object_json.env_id
					this.auto_increment_id = res.id ? res.id : 0;
					this.data_map_id = res.object_id;
					this.object_revision = res.object_revision;
					if(create_new_version){
						this.$route.params.object_revision = res.object_revision;
					}
					this.datamapname = res.object_name;
					this.oldDMName = res.object_name;
					this.manageServerResponse(res);
				})
				.catch(error_response => {
					_this.tableListLoader = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});

			// }
		},

		CopyGraph() {
			this.env_id = this.$session.get("selected_env").id;
			var data = {
				object_id: this.$route.params.data_map_id,
				object_revision: this.$route.params.object_revision,
				object_type_code: 'DM',
				env_id: this.env_id,
				ds_index: this.$route.params.ds_index
			};
			var _this = this;
			_this.tableListLoader = true;
			postToServer(
					this,
					config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
					data
				)
				.then(response => {
					var res = response;
					this.auto_increment_id = res.id ? res.id : 0;
					this.datamapname = "Copy of " + res.object_name;
					this.manageServerResponse(res)
				})
				.catch(error_response => {
					// alert(error_response)
					_this.tableListLoader = false;
					this.is_request_in_process = false
					this.is_error_occured = true;
					this.savedstatus = error_response?error_response:"Error in the saving data into the databse.";

					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});

			// }
		},
		manageServerResponse(res){
				this.startInitialSaveTimer(8000)
				if(this.$route.params.is_read_only){
		          this.isReadOnly = true
		        }
		        if(this.dragged_tbl_list.length || res.object_json.dragged_tbl_list_with_its_type.length)
		       	{
		       		this.dataForGraphRedesign =  {"cells":res.object_json.graph_data.cells,"uniqueIdentifierId":res.object_json.business_data.unique_identifier_id,"isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI,'tableFilters':res.object_json.business_data.filters}

					this.join_json = res.object_json.business_data.joins;
					this.copyJoinJson = _.cloneDeep(this.join_json)
					this.filter_json = res.object_json.business_data.filters;
					this.link_filter_json = res.object_json.business_data.link_filters || {};
					this.columns_for_unique_identifiers =
						res.object_json.unique_identifier_keys;
					console.log('this.columns_for_unique_identifiers',this.columns_for_unique_identifiers)
					this.dragged_tbl_list = res.object_json.table_list;
					if(res.object_json.dragged_tbl_list_with_its_type)
					{
						_.forEach(res.object_json.dragged_tbl_list_with_its_type,(obj)=>{
							if(obj.table_type === 'step')
								obj.table_type = 'BO'
						})
						this.dragged_tbl_list_with_its_type = res.object_json.dragged_tbl_list_with_its_type
					}
		       	}

					//
					this.bo_description = res.description
					this.Get_Tables();

					var dragged_tbl_list_with_type = [];
					for (var i = res.object_json.table_list.length - 1; i >= 0; i--) {
						dragged_tbl_list_with_type.push({
							table_name: res.object_json.table_list[i],
							table_type: "table"
						});
					}
					if(res.erp_code){
						this.erp_code = res.erp_code
						this.erp_version = res.erp_version
					}
					this.isTemplate = res.template

					// getBulkTablesColumns(
					// 	this,
					// 	this.table_column_list,
					// 	this.env_id,
					// 	this.connection_str,
					// 	this.schema,
					// 	dragged_tbl_list_with_type
					// );

		},
		close_join_dialog(closeOnly) {
			if(!closeOnly)
				this.add_join();

			// check is any join is specified or not on that link. If not then trigger remove link evnet to main graph
			// if(!this.join_json[this.lin])
			if(this.link_id){
				let current_link_join = _.find(this.join_json,this.link_id)
				if(!current_link_join || !current_link_join[this.link_id]['condition'] || !current_link_join[this.link_id]['condition'].length)
					this.cellToRemove ={
						'cell_id':this.link_id,
						'type':'link'
					}
			}
			this.new_jfrom_column_list = []
			this.new_jto_column_list = []
			this.jtype = "Inner Join"
			this.link_dialog = false;
			this.link_id = ''
		},
		perform_collapse(action) {
			var main_holder = document.getElementById("main_holder")
			var main_container =document.getElementById("main_container")
			var lens = document.getElementById("lens")
			var lens_width = lens.style.width
			if(action === 'close'){

				main_holder.style.width="98%";
				lens_width = main_container.clientWidth * this.small_paper_scale
			}
			else{

				main_holder.style.width="80%";
				lens_width =main_container.clientWidth * this.small_paper_scale
			}
			lens.style.width = Math.round(lens_width)+"px"
		},
		close_date_format_popup() {
			this.dateformat_dialog = false;
		},
		showDescription(){
	    	this.vueEditorObj = {}
	    	this.vueEditorObj['sourcepage'] ='bo'
	    	this.vueEditorObj['description'] = _.cloneDeep(this.bo_description)
	    	this.descriptionPanel = true
	    },
	    DescriptionChange(bo_description){
	    	this.bo_description = bo_description
	    	this.ChangeSaveStatus()
	    },
	    createAutoRelationship(tablecell){
	    	let _this = this
	    	try {
	    		var table_name = _this.dragged_tbl_list_with_its_type[0].table_name
	    		var table_type = _this.dragged_tbl_list_with_its_type[0].table_type
	    		var service_table_type = ''
	    		if(table_type === "table")
	    			service_table_type = 'T'
	    		else if(table_type === 'alias')
	    			service_table_type = 'A'
	    		else
	    			service_table_type = 'V'
	    		this.updatetblsprimarykeys(table_name,table_type,service_table_type,tablecell)
	    	}
	    	catch(error){

	    	}
	    },
	    updatetblsprimarykeys(table_name,table_type,service_table_type,tablecell){
	    	let _this = this
 				_this.tableListLoader = true;
	    	_this.columns_for_unique_identifiers = []
	    	this.datasource_error = ''
	    	var table_type = service_table_type
	    	var env_id = _this.env_id
	    	var env_name = this.$session.get('selected_env').name
	    	var connection_str = this.connection_str
	    	var mapped_list = []
	    	if(_this.$session.get('mapped_agent_details'))
	    		mapped_list = _this.$session.get('mapped_agent_details')
	    	var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
	    	var mapped_agent_id = ''
	    	if(mapped_agent_obj)
	    		mapped_agent_id = mapped_agent_obj.mapped_agent_id
	    	var schema = _this.schema
	    	var table_name =table_name
	    	var db_type =_this.db_type
	    	var client_id = this.$session.get('client_id')
	    	var added_by = this.$session.get('email')
	    	var data_to_pass = {"table_type":table_type,"env_id":env_id,"schema":schema,"conn_str":connection_str,"table_name":table_name,"db_type":db_type,"client_id":client_id,"env_name":env_name,"added_by":added_by,"object_type_code": "BO","mapped_agent_id":mapped_agent_id}
	    	postToServer(this, config.AGENT_API_URL + GET_PRIMARY_KEYS, data_to_pass).then(response => {
 					_this.tableListLoader = false;

	    		if(response.unique_identifier_keys){
	    			for(var i=0;i<response.unique_identifier_keys.length;i++){
	    			_this.columns_for_unique_identifiers.push(response.unique_identifier_keys[i])
	    			}
	    		}
	    		this.newCell = {
		    		'edc_type':'bo_auto_link',
		    		'source':_this.uniqueIdentifierCell,
		    		"target":tablecell
		    	}
	    	}).catch(CurrentJobError => {
 					_this.tableListLoader = false;
	    		this.generateboclick = false
	    		if(CurrentJobError){
	    			this.datasource_error = CurrentJobError
	    		}
	    		else {

	    		}
	    	})
	    },
	    designAutoRelationship(link_id,tablecell){
			this.tableListLoader = false;
	    	this.join_json = []
	    	let source_cell = this.uniqueIdentifierCell
	    	let target_cell = tablecell
	    	let unique_keys = this.columns_for_unique_identifiers
	    	var link_join_json = {
		        "jfrom":source_cell.prop("table_name"),
		        "jto":target_cell.prop("table_name"),
		        "type":"Inner Join",
		        "jfrom_id":source_cell.id,
		        "jto_id":target_cell.id,
		        "join_relationship":"1:1",
		        "has_flattening_restriction":false,
		        "hierarchyName":target_cell.prop("table_name")+" - "+ source_cell.prop("table_name"),
		        "relationshipName":source_cell.prop("table_name") +" - "+target_cell.prop("table_name")
		    }
		    var conditions = []
		    var condition_dict = {"jfrom":source_cell.prop("table_name"),
		    "jto":target_cell.prop("table_name"),
		    "operator":"_eq_"}

		    for(var i=0;i<unique_keys.length;i++){
		        var new_condition_dict = _.cloneDeep(condition_dict)
		        new_condition_dict["from_column"] = unique_keys[i]
		        new_condition_dict["to_column"] = unique_keys[i]
		        conditions.push(new_condition_dict)
		    }
		    link_join_json["condition"] = conditions
		    this.join_json.push({"id":link_join_json})
		    this.join_json[0][link_id] = link_join_json
		    delete this.join_json[0]["id"]
	    	this.ChangeSaveStatus()
	    },
	    createNewVersion:function(record){
            let _this = this;
            var inputJson =  {"object_id": this.data_map_id,
				"object_type_code": "DM", 
			 "object_revision": this.object_revision, "env_id": this.$session.get('selected_env').id}

            postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'PENDING_APPROVAL'){
                            alert("Object is in Approval pending state, Can not create New version")
                        } else {
                            this.ShowConfirmNewVersion(inputJson)
                        }
                    } else {
                       this.errorMsg = response
                    }
                }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0;
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
		},
		validate_erp(){
			var _this= this
	        if (this.$refs.obj_form.validate()) {
	        	this.erpDetails= false
	        	this.erp_code = _this.cur_erp_code
	        	this.erp_version = _this.cur_erp_version
	        	}
			},

        ShowConfirmNewVersion(object){
        let confirmFn = function () {
        this.$parent.SendObjectForNewVerion(object)
        }
        let obj = {
          title: 'Create New Version',
          message: 'This will create new version of Object and get replaced with Current version of Object, Proceed?',
          type: 'info',
          useConfirmBtn: true,
          onConfirm: confirmFn
        }
        this.$refs.simplert.openSimplert(obj)
      },
      SendObjectForNewVerion(object){
      	// let routeData = this.$router.resolve({ name: 'datamapdesigner',
       //              query: { 'data_map_id': object.object_id,
       //              'env_id': this.$session.get('selected_env').id,
       //              'object_revision': object.object_revision,
       //              'create_new_version': true,
       //              'object_type_code': 'DM'}});
       //          window.open(routeData.href, '_self');
       // this.$route.params.object_revision = ''
       this.RedesignGraph('Yes',true)
      },
      ShowObjDetails(){
      	this.reqInputData = {}
      	var data = {"object_id": this.data_map_id, "object_revision": this.object_revision}
      	this.reqInputData = data
      	this.showObjectDetails = true
      },
      closeDetailPopup(){
      	this.showObjectDetails = false
      },
	  setErrorOnBO(isHeighlight,cell_id,message){
			this.validationGraphObj = {
				'isHeighlight':isHeighlight,
				'cell_id':cell_id,
				'message':message,
			}
		},
		JoinAcceleration(jfrom){
			if(!jfrom)
				return
			var start_index = 0
			if(this.acceleratorObj.acceleration_type ==='none')
				return
			if(this.acceleratorObj.acceleration_type === "jde_ed_e1")
				start_index = 2
			// if condition for acceleration type jde/e-business
			// code for jde acceleration
			var jfrom_alias = jfrom.substring(start_index,jfrom.length)
			var jto = _.find(this.jto_column_list,function(col){
				let obj = col.column_name
				if(obj && obj.substring(start_index,obj.length) === jfrom_alias)
					return obj
			})
			if(jto)
				this.jto = jto.column_name
		},
    	loadTableDescription(table_list){
    		// set timeout for load acceleration details
    		let _this = this
    		setTimeout(()=>{
    			getTableDescriptions(this,table_list,this.env_id).then(response=>{
    				_this.tableDescriptionList = response
    			}).catch(error=>{

    			})
    		},4000)
    	},
		getDatasourceDetails(datasource_id){
			var _this = this
			let data = {'datasource':datasource_id}
			postToServer(
					this,
					config.DATA_SOURCE_URL + '/get_all_datasources_by_user_env_setting', data
				)
				.then(response => {
					let business_data = response.datasource[0].datasource_info
					_this.connection_str = business_data.connection_string
					_this.schema = business_data.schema_name
					_this.db_type = business_data.database_type
					_this.mapped_agent_id = business_data.mapped_agent_id
					_this.Get_Tables()
				})
				.catch(error_response => {
					// this is for avoid saving on error on redesign
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});
		},
		getDatasourceObj(datasource_index){
	    	// in this function, we will return the datasource details which return by getTablesByDVEnv.
	    	var _this = this
	    	if(!_this.tablelistpanelproperties.available_datasource_list[datasource_index])
	    	{
	    		alert("datasource details not found for index "+datasource_index)
	    		return {}
	    	}
		    return	_.cloneDeep(_this.tablelistpanelproperties.available_datasource_list[datasource_index])
	    },
		getCommonDetailsForColumnFetch(table_name,table_type){
	    	var _this = this
	    	return {"table_column_list":_this.table_column_list, "env_id":_this.env_id, "table_name":table_name, "table_type": table_type, "need_column_db_details":true}
	    },
    	autoArrangeBusinessObject(){
    		let _this = this
    		this.autoArrangeGraph = true
    		// reset the auto arrange for next call
    		setTimeout(()=>{
    			_this.autoArrangeGraph = false
    		},200)
    	},
		getBusinessObjectList(ds_id_list, tableResponse){
            let client_id = this.$session.get('client_id');
            let _this = this;
            let t_data = {
                'env_id': this.env_id,
                'client_id': client_id,
                "ds_id_list": ds_id_list
            }
			// var tableResponse = tableResponse
            this.loader = true;
            postToServer(this, config.PUBLISHER_URL +'/published_business_objects_in_dv', t_data, false).then(async response => {
                var data = response.result
				var res = []
                if(data){
                    	tableResponse.available_table_list.map(function(obj){
							if(data[obj.datasource_id]){
								data[obj.datasource_id].map(function(object){
									object.table_type = "EB"
									object.type = "EB"
									object.title = object.object_name
									object.name = object.object_name
								})
								obj.table_list = data[obj.datasource_id].concat(obj.table_list)}
				})
				this.tablelistpanelproperties = tableResponse
				// this.business_obj_list = [{'children':res,
				// 							'name':"Business Objects",
				// 							'id':1}];
                }
            _this.loader = false;
            }).catch(ProcessDocError => {
                _this.loader = false;
                if(ProcessDocError){
                    this.loader = null
                    this.tableList.rows = [];
                    this.tableList.total_count=0;
                }
                else {
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
    	startInitialSaveTimer(timing){
	      	let _this = this
	      	// over come issue with load object before loadding anything, I am writing set timeout for few seconds
			setTimeout(()=>{
				_this.isObjectFullyLoaded = true
			},timing)
	      },
		pushForUndo(action,data){
	    	if(action === 'dm_name_changed'){
	    		data['action_to_take'] = action
	    		data['user_action'] =action
				this.reverseData(data)
	    	} else if(action === 'add_element'){
	    		data['action_to_take'] = 'remove_element'
	    		data['user_action'] =action
	    	} else if(action === 'remove_table'){
	    		data['action_to_take'] = 'add_element'
	    		data['user_action'] = 'remove_element'
	    	} else if(action ==='add_join_condition'){
	    		data['action_to_take'] = 'remove_join_condition'
	    		data['user_action'] = action
	    	} else if(action ==='remove_join_condition'){
	    		data['action_to_take'] = 'add_join_condition'
	    		data['user_action'] = action
	    	} else if(action === 'update_join_condition'){
	    		data['action_to_take'] = action
	    		data['user_action'] = action
	    	} else if(action === 'filter_change'){
	    		data['action_to_take'] = action
	    		data['user_action'] = action
	    	}

	    	if(data.user_action)
	    		this.undoStack.push(data)

	    },
	    reverseData(data){
	    	let oldValue = data['old_value']
	    	data['old_value'] = data['new_value']
	    	data['new_value'] = oldValue
	    	return data
	    },
	    keyupHandler(event){
			if (event.ctrlKey && event.code === 'KeyZ') {
      			this.undoHandler()
    		}
    		else if (event.ctrlKey && event.code === 'KeyY') {
      			this.redoHandler()
    		}
		},
		undoHandler(){
			let _this = this
			if(!_this.undoStack.length)
				return
			let undoData = _this.undoStack.pop()
			_this.handlerUndoRedo(undoData,'undo')
			let redoData = _.cloneDeep(undoData)
			redoData['action_to_take'] = undoData.user_action
			redoData['user_action'] = undoData.action_to_take
			if(redoData.action_to_take === 'dm_name_changed'){
				this.reverseData(redoData)
			} else if(redoData.action_to_take === 'update_join_condition')
				this.reverseData(redoData)
			else if(redoData.action_to_take === 'filter_change')
				this.reverseData(redoData)
			_this.redoStack.push(redoData)
		},
		redoHandler(){
			let _this = this
			let redoData = _this.redoStack.pop()
			let undoData =  _.cloneDeep(redoData)
			undoData['action_to_take'] = redoData.user_action
			undoData['user_action'] = redoData.action_to_take
			if(undoData.action_to_take === 'dm_name_changed'){
				this.reverseData(undoData)
			} else if(undoData.action_to_take === 'update_join_condition')
				this.reverseData(undoData)
			else if(undoData.action_to_take === 'filter_change')
				this.reverseData(undoData)

			_this.undoStack.push(undoData)
			_this.handlerUndoRedo(redoData,'redo')
		},
		handlerUndoRedo(data,action){
			if(!data ||!data.action_to_take)
				return
			if(!data.isForJoin || (this.link_dialog && data.link_id !=this.link_id))
				// we have close join dialog whenever user press ctrl+z or ctrl+y only if that action is not related to currently open join dialog
				this.close_join_dialog(true)

			this.closeFilter('close')

			var _this = this
			let undoredoMsg = ''
			if(data.action_to_take === 'dm_name_changed'){
				undoredoMsg = action +": Datamap name changed from "+data.old_value + " to "+data.new_value
				this.datamapname = data.new_value
			} else if (data.action_to_take === 'remove_element'){
				undoredoMsg = action + ": Table "+data.element.table_name+" removed"
				this.cellToRemove ={
					'cell_id':data.element.id
				}
			} else if(data.action_to_take === 'add_element'){
				undoredoMsg = action + ": Table "+data.element.table_name+" added"
				data.element['edc_type'] = 'bo_table'
				data.element.relatedLinks = data.relatedLinks
				this.newCell = data.element
				this.addMoreLinkDetails(data)
			} else if(data.action_to_take === 'add_link'){
				data['edc_type'] = 'bus_link'
				data['relatedLinks'] = [data.link]
 				this.newCell = data
				this.addMoreLinkDetails(data)
				undoredoMsg = action+': Relationship added'
				if(data.relatedJoins && data.relatedJoins.length && data.relatedJoins[0][data.link.id]){
					undoredoMsg = undoredoMsg+' between '+data.relatedJoins[0][data.link.id].relationshipName
				}
			} else if(data.action_to_take === 'remove_link'){
				this.cellToRemove ={"link_id":data.link.id}
				undoredoMsg = action+': Relationship removed'
				if(data.relatedJoins && data.relatedJoins.length && data.relatedJoins[0][data.link.id]){
					undoredoMsg = undoredoMsg+' between '+data.relatedJoins[0][data.link.id].relationshipName
				}
			} else if(data.action_to_take && data.isForJoin){
				// we will handle all join condition redo-undo actions in getEventDetails method
				undoredoMsg = action+": Condtion "
				if(data.conditionJson || data.new_value){
					if(data.action_to_take === "add_join_condition"){
						undoredoMsg = undoredoMsg+" added in relationship "+data.conditionJson.jfrom+" - "+data.conditionJson.jto
					}else if(data.action_to_take === "remove_join_condition"){
						undoredoMsg = undoredoMsg+" removed from relationship "+data.conditionJson.jfrom+" - "+data.conditionJson.jto
					}else{
						undoredoMsg = undoredoMsg+" updated in relationship "+data.new_value.jfrom+" - "+data.new_value.jto
					}
				}
			} else if(data.action_to_take === 'filter_change'){
				undoredoMsg =action+": Filter modified for "
				if(data.filter_for === 'cell')
					undoredoMsg = undoredoMsg + " table"
				else
					undoredoMsg = undoredoMsg + " relationship"
				this.manageUndoRedoFilters(data)
			}

			if(undoredoMsg)
 				this.setRedoUndoMsgTimeout(undoredoMsg)
		},
		addMoreLinkDetails(data){
			if(data.relatedJoins && data.relatedJoins.length){
				this.join_json = _.merge(this.join_json,data.relatedJoins)
				this.copyJoinJson = _.cloneDeep(this.join_json)
			}
		},
		setRedoUndoMsgTimeout(message){
			var _this = this
			_this.redoundoMsg = message
			_this.redoundoTimer = null
			_this.redoundoTimer = setTimeout(()=>{
				_this.redoundoMsg = ''
			},8000)
		},
		manageJoinsRedoUndoActions(joinData,linkJoinIndex=-1){
			if(linkJoinIndex<0){
				// It means we have added a new link. Now we have to add join json into the link.
				let data = this.getJoinJson(joinData.conditionJson.from_table_name,joinData.conditionJson.from_table_name)
				data['condition'] = [joinData.conditionJson]
				this.join_json[this.join_json.length] = {}
				this.join_json[this.join_json.length-1][joinData.link_id] = data
				this.join_keys_list = this.join_json[this.join_json.length-1][joinData.link_id].condition;
				this.join_json_for_link = this.join_json[this.join_json.length-1][joinData.link_id].condition;
			}
			else{
				let joinObj = this.join_json[linkJoinIndex][joinData.link_id]
				if(joinData["action_to_take"] === "remove_join_condition"){
					joinObj.condition.splice(joinData.conditionIndex,1)
				}
				else if(joinData["action_to_take"] === "add_join_condition"){
					joinObj.condition.splice(joinData.conditionIndex,0,joinData.conditionJson)
				} else if(joinData["action_to_take"] === "update_join_condition")
					joinObj.condition.splice(joinData.conditionIndex,1,joinData.new_value)
			}
			this.copyJoinJson = _.cloneDeep(this.join_json)
		},
		manageUndoRedoFilters(data){
			// we have to close filter panel if that already open.
			this.closeFilter('close')
			// then we will assign data from the json to the page variables which we use for the filter
			this.filter_for = data.filter_for
			this.cell_id = data.cell_id
			this.filter_rows_list = data.new_value
			// now we will save this json in the respective filter json. For that purpose we will again call the closeFilter function with save action
			this.closeFilter('save',true)
		},
		templateOptionChanged(value){
			this.isTemplate = value
			this.ChangeSaveStatus()
		}
	},
};
</script>
<style>
/* port styling */
.available-magnet {
  fill: yellow;
}
/* element styling */
.available-cell rect {
  stroke-dasharray: 5, 2;
}

div#main_container {
  height: 550px;
  width: 100%;
  border: 0px dotted #000;
  overflow: scroll;
  left: 10px;
}

.lens {
  position: absolute;
  border: 1px solid #2a9393;
  draggable: true;
  transform-origin: top left;
  transform: scale(1);
}

.disabled {
  box-shadow: none;
  opacity: 0.5;
}

.label-margin label {
  margin-top: 7%;
}
.customCheckbox >>> label{
  top: 5px !important;
}
#flatteningdiv .v-label {
  margin-bottom:0.0rem !important;
}

.JoinLeftSide {
	margin-right:10px !important;
}
.JoinRightSide {
	margin-left:10px !important;
}
</style>
<style scoped>
/* radio label styling */
.radioClass >>> label{
    top: 5px !important;
	/* margin: 7px !important; */
}

</style>
