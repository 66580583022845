<template>
    <div>
        <v-card class="pa-2">
            <v-card-title class="widget-heading-standard d-flex justify-space-between">{{ title }}
                <svgicon name="cancel_v2"
                class="svg-icon-grid svg-fill-grid EDC-GridIcon justify-end" @click="closePopup"/>
            </v-card-title>
            <v-card-text>
                <div>
                    <v-row class="EDC-Row pt-3">
                        <v-col class="EDC-Col EDC-ColsSpacing">
                            <v-text-field :label="nameLabel" v-model="itemName" hide-details dense>{{isForTask}}</v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row pt-3">
                        <v-col class="EDC-Col EDC-ColsSpacing business-view-text-area">
                            <v-textarea :label="descriptionLabel" rows=1 v-model="itemDescription"
                             hide-details dense></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row pt-3" v-if="isForTask">
                        <v-col class="EDC-Col EDC-ColsSpacing">
                             <v-autocomplete autocomplete :items="taskCategoryList" item-text="category_name" 
                            item-id="category_code" 
                            v-model="taskCategory" label="Select Task Category"
                             @change="onCategoryChange" hide-details dense return-object></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row pt-3" v-if="isForTask">
                        <v-col class="EDC-Col EDC-ColsSpacing">
                             <!-- <v-autocomplete 
                             autocomplete :items="resourceList" item-text="resource_name" 
                            item-id="resource_code" v-model="taskResource" 
                            label="Select Task Resource"
                            dense hide-details
                             @change="onResourceChange" return-object></v-autocomplete> -->

                            <v-autocomplete autocomplete
                                v-model="taskResource"
                                :items="resourceList"
                                item-text="resource_name"
                                item-value="resource_code"
                                label="Select Task Resource"
                                dense
                                @input="onResourceChange" 
                                return-object
                                hide-details
                                :no-data-text="'No icons available'"
                                :menu-props="{'closeOnContentClick':true}" 
                                >
                                <template v-slot:item="{ item, props }" >
                                    <v-list-item v-bind="props" @click="selectResource(item)">
                                        <v-list-item-avatar class="resource_icon">
                                            <v-icon  style="width:0px !important;" small class="mr-2">{{ item.default_icon_class }}</v-icon> <!-- Adjust spacing here -->
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="ml-0">{{ item.resource_name }}</v-list-item-title> <!-- Adjust spacing here -->
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                
                                <template v-slot:selection="{ item }">
                                    <v-chip v-if="item" class="no-background">
                                        <v-icon small class="mr-1">{{ item.default_icon_class }}</v-icon> <!-- Adjust spacing here -->
                                        {{ item.resource_name }}
                                        </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row pt-0" v-if="isForTask">
                        <v-col class="EDC-Col">
                            <template v-for="(item,ind) in UIComponents">
                                <custom-component :key="item.id+'control'+ind"
                                        :componentDetails="item"
                                        @updateModelData="updateModelData"
                                        @onTriggerApiCall="onTriggerApiCall"
                                        :parentSelectedValues="allComponentSelectedValues"
                                        />
                            </template>
                        </v-col>
                    </v-row>
                    <v-row class="EDC-Row pt-3" v-if="isForTask">
                        <v-col class="EDC-Col EDC-ColsSpacing">
                            <v-checkbox v-model="openInNewTab" label="Open In New Tab"
                            class="radioClass">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="EDC-Row pt-3">
                    <v-col class="EDC-Col d-flex justify-center">
                        <vc-button item-text="Add" v-if="!isForUpdate" @click.native="saveData" :disabled="disabledSave"/>
                        <vc-button item-text="Update" v-if="isForUpdate" @click.native="saveData" :disabled="disabledSave"/>
                        <vc-button item-text="Cancel" @click.native="closePopup"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
.radioClass >>> label{
    top: 5px !important;
}

.resource_icon{
    height: 24px !important;
    min-width: 24px !important;
    width: 0px !important;
    /* background-color: #f5f5f5; */
    /* border-radius: 4px;  */
    /* padding: 4px;  */
    display: flex;
    align-items: center;
}
.no-background {
  background-color: transparent !important;
  color: #636363 !important;
  border: none;
  margin-left: -11px !important;
}
.no-background:hover {
  background-color: transparent !important;
  color: inherit !important;
}
/* Remove the hover circular background */
.v-chip {
  background: none !important; /* Remove background on hover */
  border-radius: 0 !important; /* Remove circular shape */
}

.v-chip:hover {
  background-color: white !important; /* Remove background color on hover */
  box-shadow: none !important; /* Remove any box shadow effect */
  cursor: default; /* Remove pointer change */
}
</style>

<script>
    import config from "../../config.json";
    import customComponent from "./controlComponent.vue"
    import {
        get as getToServer, deleteFromServer, post as postToServer
    } from './../../methods/serverCall.js';
    import { v4 as EDCuuidv4 } from 'uuid';
    import { makeAPICall,parseInputParams } from '@/methods/layoutFunc.js';
    export default{
        components:{
            'custom-component':customComponent
        },
        name:"ManageSession&Task",
        props:{
            popupObject:{
                type:Object,
                default:()=>{
                    return {}
                }
            },
        },
        data(){
            return{
                clientId:this.$session.get("client_id"),
				userName:this.$session.get("email"),
				productName:this.$route.params.product_name,
                env_id: this.$session.get("selected_env").id,
				env_name: this.$session.get("selected_env").name,
                itemName:"Untitled",
                itemDescription:"",
                taskIcon: "",
                selectedResourceData: null,
                taskResource:"",
                iconList:[],
                taskCategory:null,
                taskCategoryList:[],
                resourceList:[],
                objectJson:[],
                sectionId:"",
                sectionSequence: "",
                SelectedIcon1: '',
                onNewTask: false,
                UIComponents: [],
                openInNewTab: true
            }
        },
        computed:{
            isForSection(){
                return this.popupObject.for ==='section'
            },
            isForTask(){
                return !this.isForSection
            },
            isForUpdate(){
                if(this.popupObject.for ==='section'){
                    return this.popupObject.sectionId
                }else{
                    return this.popupObject.taskId
                }
            },
            // Computed property for popItemName with getter and setter
            title(){
                return this.isForSection?"Manage Section":"Manage Task Of "+this.popupObject.sectionName
            },
            disabledSave(){
                if(this.isForSection)
                    // on empty session name it should not enable Save button
                    return !this.itemName
                else{
                    return !this.itemName || !this.taskResource
                }
            },
            nameLabel(){
                return (this.isForSection?"Enter Section":"Enter Task")+" Name"
            },
            descriptionLabel(){
                return (this.isForSection?"Enter Section":"Enter Task")+" Description"
            },
            // Check if the widget is selected by checking if widgetObjectJson is not empty
            isWidgetSelected() {
                return !_.isEmpty(this.widgetObjectJson);
            },
            allComponentSelectedValues(){
                let allSelectedValues = {}
                if(!this.UIComponents.length)
                    return allSelectedValues
                for(var i=0;i<this.UIComponents.length;i++){
                    let currentComponent = this.UIComponents[i]
                    if(!_.isEmpty(currentComponent.selectedValue))
                        allSelectedValues[currentComponent.key] = {
                            "selectedValue":currentComponent.selectedValue
                        }
                }
                return allSelectedValues
            }
        },
        watch:{
            'popupObject':{
                handler(newValue){
                    this.managePopUp()
                }
            },
            taskResource(newVal) {
                this.onResourceChange(newVal);
            }
        },
        mounted(){
            this.managePopUp()
        },
        methods: {
            selectItem(item) {
                // Set the selected item as the taskIcon
                this.taskIcon = item;
                },
            closePopup(){
                this.$emit("closePopup")
            },
            selectResource(item) {
                // Set the selected item as the taskIcon
                this.taskResource = item;
                },
            managePopUp(){
                this.itemName = this.isForSection?"Untitled Section Name":"Untiled Task Name"
                this.onNewTask = this.popupObject.onNewTask || false
                if(this.onNewTask){
                    this.UIComponents = []
                }
                this.itemDescription = ""
                this.sectionId = this.popupObject.sectionId || ""
                this.sectionName = this.popupObject.sectionName || ""
                this.sectionDescription = this.popupObject.sectionDescription || ""
                this.sectionSequence = ""
                if(_.isEmpty(this.popupObject))
                    return
                
                if(this.isForSection){
                    // handle section variables here
                    this.itemName = this.popupObject.sectionName || this.itemName
                    this.itemDescription = this.popupObject.sectionDescription || ""
                    this.sectionId = this.popupObject.sectionId || EDCuuidv4()
                } else{
                    this.itemName = this.popupObject.taskName || this.itemName
                    this.itemDescription = this.popupObject.taskDescription || ""
                    this.openInNewTab = this.popupObject.openInNewTab===false?false:true
                    this.taskId = this.popupObject.taskId || EDCuuidv4()
                    this.SelectedIcon1 = this.popupObject.taskIcon || ""
                    this.taskCategory = this.popupObject.taskCategory || ""
                    this.taskResource = this.popupObject.taskResource || ""
                    
                    if (this.taskCategory.category_code){
                        this.getResourceList(this.taskCategory.category_code)
                    }
                    this.onResourceChange(this.taskResource)
                    //handle task variables and get call here
                    // this.getIconList()
                    this.getTaskCategoryList()
                }

            },
            getIconList(){
                var _this = this
                var url = config.REPOSITORY_API_URL + "/get_all_icons"
                this.loader = true
                getToServer(_this, url).then(response=>{
                    _this.iconList = response
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            getTaskCategoryList(){
                 var _this = this
                var url = config.REPOSITORY_API_URL + "/get_all_task_category"
                this.loader = true
                getToServer(_this, url).then(response=>{
                    _this.taskCategoryList = response
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            getResourceList(category_code){
                 var _this = this
                var url = config.REPOSITORY_API_URL + "/get_all_resources"
                var data = {
                    "category_code": category_code
                }
                this.loader = true
                postToServer(_this, url, data).then(response=>{
                    _this.resourceList = response
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            getResourceByType(resource_code){
                 var _this = this
                var data = {
                    "resource_code": resource_code
                }
                var url = config.REPOSITORY_API_URL + "/get_resources_by_task"
                this.loader = true
                postToServer(_this, url, data).then(response=>{
                    _this.selectedResourceData = response
                    _this.resource_json = _this.selectedResourceData
                    _this.UIComponents = response.resource_json.ui_components
                    _this.mapUiComonentData(this.popupObject.selectedValueForUiComponents)
                    this.onNewTask = false
                    this.loader = false
                }).catch(error=>{
                    this.loader = false
                    alert(error)
                })
            },
            onResourceChange(item) {
                if (item.resource_code === "SEE_OBJ_IN_PATHFINDER" || 
                item.resource_code === "SETUP_EXTERNAL_CALL") {
                    this.getResourceByType(item.resource_code);
                } else {
                    this.UIComponents = []; // Clear UIComponents if the condition is not met
                }
            },
            saveData(){
                if(this.isForSection){
                    this.saveSection()
                } else
                    this.saveTask()
            },
            onCategoryChange(item){
                this.UIComponents  = []
                this.onNewTask = true
                this.getResourceList(item.category_code)
            },
            mapUiComonentData(uiComponentData){
                var _this = this
                if(_.isEmpty(uiComponentData))
                    return
                for(let i=0;i<this.UIComponents.length;i++){
                    let keyVal = this.UIComponents[i].key
                    if(uiComponentData[keyVal]){
                        this.UIComponents[i]['selectedValue'] = uiComponentData[keyVal].selectedValue
                    }
                }
            },
            saveTask(){
                var _this = this
                var data = {
                    "sectionId": this.popupObject.sectionId,
                    "taskId": this.popupObject.taskId || EDCuuidv4(),
                    "taskName":this.itemName,
                    "taskDescription":this.itemDescription,
                    "taskCategory":this.taskCategory,
                    "category_code":this.taskResource.category_code,
                    "taskResource":this.taskResource,
                    "openInNewTab": this.openInNewTab,
                    "selectedValueForUiComponents": {
                    }
                }
                for(let i=0;i<this.UIComponents.length;i++){
                    let currentComponent = this.UIComponents[i]
                    data.selectedValueForUiComponents[currentComponent.key] = {"selectedValue": currentComponent.selectedValue}
                }
                _this.$emit("saveTask",data,this.popupObject.sectionId)
            },
            saveSection(){
                var _this = this
                var sectionId = this.popupObject.sectionId || EDCuuidv4()
                var data = {
                    "sectionId": sectionId,
                    "sectionName":this.itemName,
                    "sectionDescription":this.itemDescription
                    }
                _this.$emit("saveSection",data, sectionId)
            },
            updateModelData(componentKey,modelValue){
                let currentControl = _.find(this.UIComponents,["key",componentKey])

                if(currentControl){
                    currentControl['selectedValue'] = modelValue
                }
                    
            },
            onTriggerApiCall(APIDetails,selectedObject){
                var _this = this
                this.widgetTitle = ""
                // make sure all required params are there, not needed now but -> todo
                let inputData = parseInputParams(this,APIDetails.params,_.cloneDeep(this.allComponentSelectedValues))
                // console.log("inputData",JSON.stringify(inputData))
                this.isLoading=true
                makeAPICall(this,APIDetails,inputData).then(response=>{
                    this.isLoading=false
                }).catch(error=>{
                    this.isLoading=false
                    alert("error in API onTriggerApiCall")
                })
            },

        },
    }
</script>